.sitenav-comp {
  width: 48px;
  .sitenav-content {
    .logo-content {
      width: 48px;
    }
  }
  .nav-content {
    nav {
      ul, li {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      .snav-link {
        & > div {
          padding: 0 0 0 48px;
          width: 0;
          transition: $transitionWidth;
        }
      }
    }
  }
  &.active {
    width: 368px;
    .sitenav-content {
      .logo-content {
        width: 368px;
      }
    }
    .nav-content {
      nav {
        .snav-link {
          & > div {
            padding: 8px 8px 8px 48px;
            width: 100%;
          }
        }
      }
    }
  }
}
.sitenav-mask {
  display: block;
  &.active {
    transform: translateX(0);
  }
}
$white:    #fff;
$gray-100: #f8f8f8;
$gray-200: #e9e9e9;
$gray-300: #dedede;
$gray-400: #cecece;
$gray-500: #adadad;
$gray-600: #6c6c6c;
$gray-700: #494949;
$gray-800: #343434;
$gray-900: #212121;
$black:    #000;

//$primary: #3F749C;
$primary: darken(#1DBAAD, 7.5%);
//$primary: #0088CC;
$primary-light: #3e847b;

$green:#3e847b;

$secondary: #40403f;
$success: #1DBAAD;
$info: #3F749C;
$warning: #FCB127;
$danger: #9F1A20;
$light: #EEEEEE;
$dark: #2A2A2A;

$brandRed: $danger;
$brandBlue: $info;
$brandAqua: $success;
$brandYellow: $warning;
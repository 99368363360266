.bg__progress {
  background-color: #fcfcfc;
  border-radius: 0.5em;
}

.bg__user {
  background-color: #ebebeb;
}

.bg__page {
  background-color: #fcfcfc;
}

.bg__bot {
  background-color: #eee;
}
.bg__module {
  background-color: #dedede !important;
}

.width__90 {
  min-width: 90vw;
  max-width: 85%;
}
.avatar {
  margin-top: 10px;
  max-width: 150px;
}

.circle {
  max-width: 150px;
}
.flex__column__center {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.full__name {
  font-size: 28px;
  display: flex;
  place-content: center;
}
.course__progress {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  margin-left: 25px;
  span{
    font-size: 22px;
    font-weight: 600;
  }
}
.primary__color {
  color: #3e847b;
}
.stepData__color {
  color: #2799d3;
}

.font__bold {
  font-weight: bold;
}

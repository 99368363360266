$transitionTransform: transform 250ms ease;
$transitionWidth: width 250ms ease;
$transitionOpacity: opacity 250ms ease;
.offcanvas-comp, .offcanvas-mask {
  position: fixed;
  top: 0;
  right: 0;
}
.offcanvas-comp {
  z-index: 3324;
  background-color: $gray-100;
  width: 100%;
  height: 100%;
  transform: translateX(100.1%);
  transition: $transitionTransform;
  .offcanvas-content {
    width: 100%;
  }
  .oc-header {
    height: 48px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .oc-content {
    flex-grow: 1;
    overflow-y: auto;
    height: calc(100% - 48px);
  }
  .offcanvas-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 48px;
    background-color: transparent;
    border: 0;
    color: $gray-700;
    visibility: hidden;
    opacity: 0;
    transition: $transitionOpacity;
    transition-delay: 250ms;
  }
  &.active {
    transform: translateX(0);
    .offcanvas-close {
      visibility: visible;
      opacity: 1;
    }
  }
}
.offcanvas-mask {
  background-color: rgba($gray-700, 0.9);
  display: none;
  z-index: 3312;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  transition: $transitionTransform;
}
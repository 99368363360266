master-data-height {
  height: 80vh;
}

.tkl-spinner-master-data {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 5000;
}
.master-data {
  .ag-theme-alpine {
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
  }
  .icon-font .material-icons {
    font-size: 30px;
  }
  .cta-comp.no-text.btn-sm {
    &:focus {
      outline: none;
      border: 0px;
      box-shadow: none;
    }
  }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  /* color: #494949; */
  background-color: #fff;
  //border-color: #18998e #18998e #fff;
  //border-bottom: 5px solid darken(#18998e, 7.5%);
}
.nav-tabs {
  :hover {
    //border-bottom: 1px solid #18998e;
  }
  //border-bottom: 1px solid #18998e;
}

.table-fixed tbody {
  height: 300px;
  overflow-y: auto;
  width: 100%;
}

.table-responsive {
  display: block;
  width: 100%;
  height: 500px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table th,
.table td {
  padding: 0.25rem;
  vertical-align: center;
  border-top: 1px solid #dedede;
}

.content-center {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
@media (min-width: 35.75em) {
  .master-data .modal-dialog {
    max-width: 1000px;
    margin: 1.75rem auto;
  }
}

.multiple-select {
  position: relative;
  .add-padding-to-container {
    .input_field_multiSelect {
      margin-top: -2em !important;
      margin-bottom: 20px;
    }
    .input-element-multiSelect {
      position: absolute;
      top: 65px;
      right: 0;
      left: 0;
      margin-left: 15px;
      width: inherit;
    }
    .cohorts-multiselect {
      .input-element-multi-select-dropdown {
        width: initial;
        max-width: 100% !important;
        width: 100% !important;
      }
    }
  }

  .cohorts-multiselect .input-element {
    margin: 0px;
  }

  .cohorts-multiselect {
    margin-top: -1.2em;
    .input_field_multiSelect {
      width: initial;
      max-width: 100% !important;
      width: 100% !important;
      margin-bottom: 10px;
      .input-element-multiselect-nodropdown {
        margin-left: 0.25em;
      }
    }
  }
}

.ag-root-wrapper {
  border-radius: 10px;
}

.bg-light {
  background-color: #fff !important;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  //border: 1px solid #18998e;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  :hover {
    margin-bottom: -1px;
    //border: 1px solid #18998e;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  :active {
    margin-bottom: -1px;
    //border: 1px solid #18998e;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
}

.admin-data-header {
  .title {
    margin: 0%;
    font-weight: 600;
    margin-top: 0.25em;
    font-size: 25px;
    margin-left: 0.5em;
  }
  .nav-pills {
    font-size: 22px;
    .dropdown {
      .nav-link {
        padding-right: 1.2em;
      }
      position: relative;
      .dropdown-toggle::after {
        position: absolute;
        top: 1.1em;
      }
    }
  }
}

.master-data-width-sm-devices {
  @media (max-width: 500px) {
    width: 120vh;
  }
}

.faq-styles {
  padding-left: 3em;
  .update-style {
    justify-content: flex-start;
    .btns {
      width: 300px;
      font-size: 18px;
    }
  }
  .parse-container {
    border: 2px solid #dedede;
    margin-top: 1.2em;
    max-height: 400px;
    overflow: auto;
  }
  .editor {
    margin-top: 1.2em;
  }
  .footer-styles {
    justify-content: flex-end;
    margin-top: 1.2em;
    .btns {
      width: 150px;
      font-size: 18px;
    }
  }
}
.center-align {
  text-align: center;
}
.center-header {
  margin-left: 1em;
  // margin-left: calc(40%);
}
.center-header-tag {
  margin-left: calc(100px - 60px);
}

.center-header_entries {
  margin-left: 0.5em;
}
.select-container {
  position: relative;
  .select-arrow-icon {
    position: absolute;
    right: 1.1em;
    font-size: 0.9em;
    color: #494949;
    font-weight: bold;
    top: 0.4em;
    outline: none;
    pointer-events: none;
  }
  select {
    padding-left: 0.5em;
    cursor: pointer;
  }
}
.select-styles {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  width: 100%;
}

.multiple-select .add-padding-to-container .input-element-multiSelect {
  top: 55px;
}
.master-data-grid-style {
  padding-right: 0.5em !important;
  @media (max-width: 740px) {
    padding-left: 0em !important;
  }
  .admin-data-header {
    .nav-item.dropdown {
      .dropdown-toggle.nav-link {
        border: 1px solid #18998e;
      }
    }
  }
}
.grid-align-style {
  margin-left: calc(100px - 90px);
}
.ag-theme-tag {
  .ag-ltr .ag-cell {
    border-right: 1px solid #dedede !important;
  }
  .label-header {
    border-right: 2px solid #dedede !important;
  }
}
.faq-styles {
  margin: 0px;
  .parse-container {
    padding: 1rem;
  }
}
.react_calender_popup {
  .react-calendar {
    position: fixed;
  }
}
.dateRangePopup {
  .modal-dialog {
    .modal-content {
      position: absolute;
      top: 0px;
    }
  }
}
.assign_course_stlyes {
  .modal-dialog {
    width: 700px;
    @media (max-width: 650px) {
      width: 500px;
    }
    .modal-body {
      overflow: auto;
    }
  }
}

.tile_p_nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tile_p_nav_group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}
.tile_p_controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex: 0.2;
}

.tile_b_controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  // flex: 0.2;
}


.tile_b_sortBy {
  position: relative !important;
  
}



.tile_p_sortBy {
  position: relative !important;
}

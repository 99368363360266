.nb-c {
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  position: relative;
  vertical-align: top;
  display: inline-block;
  .nb-i, .nb-t, svg {
    width: 100%;
    height: 100%;
  }
  .nb-i, .nb-t {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  svg {
    fill: $brandRed;
    vertical-align: top;
  }
  .nb-t {
    z-index: 2;
    color: $white;
    letter-spacing: rem(-0.5px) !important;
    svg {
      letter-spacing: rem(-0.5px) !important;
      font-size: em(20px);
      font-weight: 700;
      fill: $white;
    }
  }
  .nb-p {
    position: absolute;
    top: rem(-8px);
    right: rem(-12px);
    font-size: em(24px);
    color: $brandRed;
  }
}
@import "./../../imports";
@import "./scss/default";

// DON'T ADD ANY SCSS IN XS
@media (min-width: $xs) {
  @import "./scss/xs";
}

@media (min-width: $sm) {
  @import "./scss/sm";
}
@media (min-width: $md) {
  @import "./scss/md";
}
@media (min-width: $lg) {
  @import "./scss/lg";
}
@media (min-width: $xl) {
  @import "./scss/xl";
}
@media (min-width: $xxl) {
  @import "./scss/xxl";
}

.send_invitation_btn {
  color: white !important;
  border: 1px solid #ab4bc6 !important;
  background-color: #ab4bc6;
}
.send_invitation_btn:hover {
  color: #ab4bc6 !important;
  border: 1px solid #ab4bc6 !important;
  background-color: white !important;
}

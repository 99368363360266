.nav .nav-tabs {
  a {
    background: black;
  }
}

.tkl-admin {
  .input-element-multiselect-nodropdown {
  }

  .multiple-select
    .cohorts-multiselect
    .input_field_multiSelect
    .input-element-multiselect-nodropdown {
    margin-top: 0.35em !important;
  }

  .nav .nav-tabs > a {
    background: black;
  }

  .nav.nav-pills {
    font-weight: 700;
  }

  .btn-link {
    font-weight: 700;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #494949;
    background-color: aliceblue;
    border-color: transparent;
  }

  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    font-weight: 700;
  }
  .ag-theme-alpine {
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
  }

  .ag-root-wrapper {
    border-radius: 10px;
  }

  .input-group-append {
    margin-left: 0px;
  }

  .parent {
    display: flex;
    margin-left: auto;
  }

  .childOne {
    margin-left: auto;
  }

  .pagination {
    justify-content: center;
  }

  .nav {
    border-bottom: 1px solid #cecece;
  }

  .parentAdd {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
  }

  .border-left {
    border-bottom-left-radius: 25rem;
    border-top-left-radius: 25rem;
  }

  button.dropdown-toggle .btn .btn-outline-primary {
    border-bottom-left-radius: 25rem;
    border-top-left-radius: 25rem;
  }
  #dropdown-Btn {
    border-bottom-left-radius: 25rem;
    border-top-left-radius: 25rem;
    border-color: #dedede !important;
  }

  .border-right {
    border-bottom-right-radius: 25rem;
    border-top-right-radius: 25rem;
    border-color: #dedede !important;
  }
  #dropdown-rounded {
    border-radius: 25rem;
  }
  .modal-dialog {
    max-width: 50%;
    margin: 1.75rem auto;
    margin-bottom: 1rem;

    .form-group {
      margin-bottom: 1rem;
    }
  }
}

.tkl-admin-add {
  .form-group {
    margin-bottom: 1rem;
  }
  .button-align-in-input-popup {
    position: sticky; //fixed
    margin-top: 0.2em;
    svg {
      fill: darken(#1dbaad, 7.5%); //E15639
    }
  }

  .delete-svg {
    fill: #e15639 !important;
  }
}

.multiple-select {
  position: relative;
  .add-padding-to-container {
    .input_field_multiSelect {
      margin-top: -2em !important;
      margin-bottom: 20px;
    }
    .input-element-multiSelect {
      position: absolute;
      top: 65px;
      right: 0;
      left: 0;
      margin-left: 15px;
      width: inherit;
    }
    .cohorts-multiselect {
      .input-element-multi-select-dropdown {
        width: initial;
        max-width: 100% !important;
        width: 100% !important;
      }
    }
  }

  .cohorts-multiselect .input-element {
    margin: 0px;
  }

  .cohorts-multiselect {
    margin-top: -1.2em;
    .input_field_multiSelect {
      width: initial;
      max-width: 100% !important;
      width: 100% !important;
      margin-bottom: 10px;
      .input-element-multiselect-nodropdown {
        margin-left: 0.25em;
      }
    }
  }
}

@media (min-width: 35.75em) {
  .master-data .modal-dialog {
    max-width: 1000px;
    margin: 1.75rem auto;
  }
}
.bg-light-blue {
  background-color: lightblue;
}

.tkl-spinner-master-data {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 5000;
  background-color: transparent;
}

.tkl-spinner-master-data_get_account {
  position: fixed;
  top: 75%;
  left: 50%;
  z-index: 5000;
  font-size: 1.2em;
  background-color: transparent;
}

.content-center {
  display: block !important;
  flex-wrap: wrap;
  align-content: center;
}

.select-container {
  position: relative;
  .select-arrow-icon {
    position: absolute;
    right: 1.5em;
    font-size: 0.9em;
    color: #494949;
    font-weight: bold;
    top: 0.4em;
    outline: none;
    pointer-events: none;
  }
  select {
    padding-left: 1.2em !important;
    cursor: pointer;
  }
}
.select-styles {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  width: 100%;
}

.role-styles {
  .multiple-select .cohorts-multiselect .input_field_multiSelect {
    padding-right: 0.5em;
    .input-element-multiselect-nodropdown {
      margin-top: 0.4em !important;
      margin-left: -0.05 !important;
    }
  }
}
// .rolestyle-arrow {
//   .cohorts-multiselect .input-element .keyboard_arrow_style {
//     margin-right: 4px;
//   }
// }

.lo_styles {
  .multiple-select
    .cohorts-multiselect
    .input_field_multiSelect
    .input-element-multiselect-nodropdown {
    margin-top: 0.3em !important;
  }
  .cohorts-multiselect .input-element .keyboard_arrow_style {
    margin-right: -0.2em !important;
  }
}
// .center-align {
//   text-align: center;
// }
// .center-header {
//   margin-left: 1em;
// }

.code_type_styles {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  label {
    display: unset;
    font-size: 18px;
  }
  .cohorts-multiselect {
    margin-top: -25px;
    margin-left: 20px;
    .input-element .search-results-div {
      width: calc(100% - 20px);
      right: -2px;
    }
  }
}

.modal-styles-addCode {
  .modal-dialog {
    max-width: 80% !important;
    .code_generation_styles {
      display: flex;
      Button {
        height: 32px;
        width: 200px;
        padding: 0;
        padding-bottom: 0.5em;
        background-color: white;
        color: darken(#1dbaad, 7.5%);
        border-color: darken(#1dbaad, 7.5%);
        margin-left: 0.5em;
        &:hover {
          background-color: darken(#1dbaad, 7.5%);
          color: white;
        }
      }
    }
  }
}

.resize-for-small-devices {
  @media (max-width: 700px) {
    width: 130vh;
  }
}

.clipboard_styles {
  .clipboard_icon {
    position: absolute;
    right: 20px;
    top: 5px;
  }
}

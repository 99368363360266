.item-not-selected {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  height: 100%;
}
.account-button-style {
  float: right;
}
.members-count-account {
  width: 100%;
  @media (max-width: 500px) {
    margin-left: 0.2em;
    margin-top: 2em;
  }
}

.tkl-dashboard-tile-spinner {
  width: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 65%;
  left: 50%;
}

.my_learning_days {
  padding: 0 !important;
  top: 8px;
  position: absolute;
  right: 0;
}

.sort-by-dropdown-styles_facilitator {
  position: absolute;
  right: 0;
  width: 11.9em;
  background-color: antiquewhite;
  border-radius: 20px;
  font-weight: 600;
  font-size: 16px;
  z-index: 1000;
  @media (max-width: 600px) {
    display: block;
    width: 200px;
  }
  .Dropdown-control {
    cursor: pointer;
    position: relative;
    overflow: auto;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    // color: #333;
    outline: none;
    //padding: 8px 52px 8px 10px;
    padding: 8px;
    transition: all 200ms ease;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    .Dropdown-arrow-wrapper {
      position: absolute;
      right: 10px;
      font-size: 22px;
      overflow: hidden;
      top: 5px;
    }
  }
  .Dropdown-menu {
    background-color: white;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 42px;
    width: 100%;
    border-radius: 20px;
    padding: 0.5em 0em 0.2em 0.5em;
    .Dropdown-option {
      cursor: pointer;
      padding: 0.5em;
      padding-left: 0em;
      padding-top: 0em;
    }
  }
}
.p-1 {
  justify-content: flex-end;
}

.my_assigned_courses_style {
  display: flex;
  width: 100%;
  padding-left: 4.5rem;
  @media (max-width: 740px) {
    padding-left: 3.5rem;
  }
  flex-wrap: wrap;
}

.row_bottom_border {
  border-bottom: 2px solid #40403f;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.course_release {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
}
.days_left_tile_a * {
  font-size: 24px !important;
  font-weight: 800;
}

.tile_e_icons:hover {
  transform: scale(1.2);
}

.tile_e_group_tile_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
}
.tile_e_group_tile {
  border: 3px solid lightblue;
  outline: 1px solid darkblue;
  margin: 5px;
  border-radius: 0.5em;
  padding: 5px;
  max-width: 180px;
  min-width: 180px;
}
.tile_e_group_tile_name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}

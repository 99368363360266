$transitionWidth: width 250ms ease;
$transitionOpacity: opacity 250ms ease;
.sitenav-comp,
.sitenav-mask {
  position: fixed;
  top: 0;
  left: 0;
}
.sitenav-comp {
  z-index: 3224;
  background-color: $gray-900;
  width: 0;
  height: 100%;
  transition: $transitionWidth;
  .sitenav-content {
    width: 100%;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .logo-content {
    position: relative;
    transition: $transitionWidth;
    width: 0;
    a {
      height: 48px;
      width: 140px;
      padding: 8px;
      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
  .sitenav-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 48px;
    background-color: transparent;
    border: 0;
    color: $white;
    visibility: hidden;
    opacity: 0;
    transition: $transitionOpacity;
    transition-delay: 250ms;
  }
  .nav-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 48px);
    justify-content: space-between;
    overflow-y: auto;
    position: absolute;
    top: 48px;
    width: 100%;
    nav {
      ul,
      li {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      li {
        position: relative;
        .btn {
          position: absolute;
          right: 8px;
          top: 50%;
          margin-top: rem(-15px);
          visibility: hidden;
          opacity: 0;
          transition: $transitionOpacity;
          transition-delay: 250ms;
          width: rem(128px);
        }
      }
      .snav-link {
        display: block;
        text-transform: uppercase;
        color: $white;
        &:hover {
          text-decoration: none;
        }
        & > div {
          height: 48px;
          overflow: hidden;
          padding: 8px 8px 8px 48px;
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: left;
        }
        .icn {
          align-items: center;
          display: flex;
          font-size: rem(24px);
          height: 48px;
          justify-content: center;
          left: 0;
          position: absolute;
          top: 0;
          width: 48px;
        }
      }
    }
  }
  &.active {
    width: 100%;
    .logo-content {
      width: 100%;
    }
    .sitenav-close {
      visibility: visible;
      opacity: 1;
    }
    .nav-content {
      nav {
        li {
          position: relative;
          .btn {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}
.sitenav-mask {
  background-color: rgba($white, 0.9);
  display: none;
  z-index: 3212;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  transition: transform 250ms ease;
}

.edit-profile-on-hover {
  cursor: pointer;
  border-radius: 100%;
  background: dimgray;
  padding: 0.3em;
  font-size: 18px !important;
  color: #fff;
  margin-left: 0.25em;
  margin-top: 0.15em;
  &:hover {
    color: #c9d8e3;
  }
}
// .btn.btn-secondary-green {
//   color: #fff;
//   background-color: #1a9f93;
//   border-color: #1a9f93;
// }
// .btn-secondary-color {
//   color: #fff !important;
//   background-color: #6c757d !important;
//   border-color: #6c757d !important;
// }

.btn-outline-no-bg.btn-primary-no-bg {
  color: #000;
  background-color: #fff;
  border: 2px solid #3e847b;
  &:hover {
    background-color: #3e847b;
    color: #fff;
  }
}
.btn.btn-secondary-blue {
  background-color: #0088cc;
  color: #fff;
  border: #0088cc;
}

.scrollbar_styles.hide_scrollbar {
  ::-webkit-scrollbar {
    display: none;
  }
}
// scrollbar_styles hide_scrollbar
.onHover_underline:hover {
  text-decoration: underline;
}

.validation-color-description {
  color: #dc3545;
}

.content-center-master-data {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.btn-secondaryArrows {
  color: #fff;
  background-color: #1dbaad;
  border-color: #1dbaad;
  &:hover {
    color: #fff;
    background-color: #2d2d2c;
    border-color: #262626;
  }
}

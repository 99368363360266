.FileStorageRoot__toolbar{
    display: flex;
    justify-content: center;
    background-color: lavender;
    border-bottom: 10px solid lavenderblush;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em
}

.FileStorageRoot__toolbar__folder__icon{
    font-size: 48px !important;
    color: green;
    cursor: pointer !important;
}

.FileStorageRoot__Activity__container{
    padding: 10px;
    align-items: flex-start;
    display:flex;
    min-height: 600px;
    justify-content: flex-start !important;
}

.FileStorageRoot__Activity__container__center{
    padding: 10px;
    align-items: center;
    display:flex;
    min-height: 600px;
    justify-content: center !important;
}
.FileStorageRoot__Activity__container__content{
    padding: 10px;
    align-items: center;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start !important;
}

.FileStorageRoot__Activity__toolbar{
    background-color: aliceblue;
    min-height: 50px;
    padding: 5px;
    align-items: center;

}


.FileStorageRoot__Activity__folder__container{
    position: relative !important;
    padding: 10px;
    margin: 10px;
    max-height: 180px;
    height: 180px;
    background-color: ghostwhite;
    border-radius: 1em; 
    max-width: 220px;
    width: 220px;
}
.FileStorageRoot__Activity__folder__icon{
    font-size: 56px !important;
    color: rgb(255, 247, 0);
    cursor: pointer !important;
}

.FileStorageRoot__Activity__folder__more__vert{
    font-size: 24px !important;
    position: absolute !important;
    top: 5px;
    right: 5px;
}


.fileStorage__folder__dropdown {
   top: 5px;
   right: 5px;
   position: absolute !important;
}

.FileStorageRoot__Activity__folder__container__label{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
}

.FileStorageRoot__Activity__File__root{
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;    
    -webkit-font-smoothing: antialiased;
}

@media (min-width: 742px){
   .container_padding{
    padding-left: 6em !important;

   }

}
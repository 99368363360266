.logo-header {
  svg {
    width: 30%;
    margin-top: 2em;
  }
  p {
    margin-top: 0.8em;
    margin-bottom: 0em;
    font-size: 18px;
    font-weight: 500;
  }
}
.not_found_card {
  .page_not_found {
    width: 100%;
    font-size: 50px;
    font-weight: 400;
    font-family: fangsong;
    margin-top: 0.8em;
    align-items: flex-end;
  }
  .not_available {
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    p {
      margin-bottom: 0.2em;
    }
  }
  .go_back {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 3.5em;
    button {
      font-size: 30px;
      padding-right: 0;
    }
    .material-icons {
      margin-top: 0.58em;
      font-size: 25px;
      color: #18998e;
    }
  }
}

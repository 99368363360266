.dropdown-item-tkl {
  &:hover {
    background-color: #fff;
  }
}
.alignment-styles {
  @media (min-width: 900px) {
    margin-left: 4em;
  }
}
.btn-notification {
  position: relative;
  top: 12px;
  padding: 0px;
}
.u-avtr,
.c-avtr,
.r-avtr,
.i-avtr {
  z-index: 2;
  overflow: hidden;
}
.custom-scroll-box,
.profile-bio {
  .c-avtr .c-avtr-img img,
  .u-avtr .u-avtr-img img {
    object-fit: cover;
  }
}
.notifications {
  .dropdown-menu.show {
    padding-top: 5px;
  }
  a.dropdown-item {
    border-bottom: 1px solid #ccc;
    white-space: normal;
    .row {
      font-size: 0.8rem;
      font-weight: normal;
    }
    .data_color {
      margin-top: 10px;
      .col {
        white-space: nowrap;
        &:first-child {
          padding-left: 0px;
        }
      }
    }
    &:hover {
      background-color: #f8f8f8;
      .unread_notification,
      .read_notification {
        background-color: #f8f8f8;
      }
    }
  }
}
.u-avtr .u-avtr-img img {
  object-fit: cover;
}
.user-toggle {
  .u-avtr-img,
  .u-avtr-cred {
    position: static;
    border: 0px !important;
  }
  .u-avtr {
    border: 0px !important;
    position: static;
  }
}

.cart_icon {
  bottom: 0em;
  position: absolute;
}
.cartlen_number {
  font-size: 1.25rem;
  top: -1.1rem;
  strong {
    font-size: 20px;
    font-weight: 900;
    color: #dc3545;
  }
}

.alignment-styles {
  .tkl_header_links:hover {
    color: #dd1d45 !important;
    background: #fff;
  }
  #basic-nav-dropdown-about:hover {
    color: #dd1d45 !important;
  }
  #basic-nav-dropdown-programs:hover {
    color: #dd1d45 !important;
  }
}
.nav-content {
  scrollbar-width: none;
}

.tc-comp {
  .tc-name-wrap {
    width: 50%;
    &.profile-bio {
      width: 75%;
    }
  }
  .tc-right-one, .tc-right-two {
    width: 25%;
  }
}
.cta-comp {
  position: relative;
  ._pi, ._ci {
    display: inline-block;
    height: rem(16px);
    // line-height: 1;
    position: relative;
    // top: rem(2px);
  }
  ._pi {
    margin-right: rem(8px);
  }
  ._ci {
    margin-left: rem(8px);
  }
  &.no-text {
    padding-left: 0;
    padding-right: 0;
    width: rem(38px);
    &.btn-sm {
      width: rem(30.57px);
    }
    &.btn-lg {
      width: rem(47.71px);
    }
    ._pi, ._ci {
      margin: 0;
    }
  }
}
button.btn.rounded-pill.cta-comp.btn-outline-info.btn-sm.icon-l {
  color: white;
  border-color: #3F749C;
  background-color: #3F749C;
}
.cta-inline-dd {
  .cta-comp {
    &:after {
      display: none;
    }
  }
}
.appended .cta-comp {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.prepended .cta-comp {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tkl-header {
  padding: 0rem;

  #basic-navbar-nav {
    font-weight: 500;
  }

  @media (max-width: 768px) {
    .login-section {
      align-items: center;
      position: relative;
      right: 0;
    }
  }

  @media (min-width: 1024px) {
    .login-section {
      position: absolute;
      // left: 80%;
      right: 0;
    }

    #basic-navbar-nav {
      position: absolute;
      left: 10% !important;
    }
  }

  @media (min-width: 1366px) {
    .login-section {
      display: flex;
      align-items: center;
      position: absolute;
      // left: 65%;
      right: 0;
    }

    #basic-navbar-nav {
      position: absolute;
      left: 10% !important;
    }
  }

  @media (min-width: 1440px) {
    .login-section {
      align-items: center;
      position: absolute;
      // left: 70%;
      right: 0;
    }

    #basic-navbar-nav {
      position: absolute;
      left: 10% !important;
    }
  }

  /* CSS used here will be applied after bootstrap.css */
  .nav {
    margin-bottom: 0;
  }
  a {
    color: black !important;
  }

  #basic-nav-dropdown-programs + .dropdown-menu {
    width: 120vw;
    // width: 110vw;
    margin-top: 2rem !important;

    .navbar-light .navbar-nav .show > .nav-link,
    .navbar-light .navbar-nav .active > .nav-link,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .nav-link.active {
      color: #dd1d45 !important;
    }

    align-items: stretch;

    @media (min-width: 1024px) {
      .dropdown_items_programs {
        margin-left: 80px;
      }
      margin-left: -55vw !important;
      margin: 0px;
      //display: flex;justify-content: space-evenly;
      padding: 2rem 4rem;
      a {
        padding: 0rem 1rem;
      }
      .header-buttons {
        display: none;
      }
    }

    @media (min-width: 1366px) {
      .dropdown_items_programs {
        margin-left: 80px;
      }
      margin-left: -45vw !important;
      padding: 2rem 10rem;
      padding-bottom: 1em;
      a {
        padding: 0rem 3rem;
      }
    }

    @media (min-width: 1920px) {
      .dropdown_items_programs {
        margin-left: 80px;
      }
      margin-left: -30vw !important;
      margin: 0px;
      //display: flex;justify-content: space-evenly;
      padding: 2rem 20rem;

      padding-bottom: 1em;
      a {
        padding: 0rem 1rem;
      }
      .header-buttons {
        display: none;
      }
    }
  }

  #basic-nav-dropdown-about + .dropdown-menu {
    margin-left: -75vw !important;
    width: 120vw;

    align-items: stretch;
    margin-top: 2rem !important;

    .navbar-light .navbar-nav .show > .nav-link,
    .navbar-light .navbar-nav .active > .nav-link,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .nav-link.active {
      color: #dd1d45 !important;
    }

    @media (min-width: 1024px) {
      padding: 2rem 20rem;
      a {
        padding: 0rem 3rem;
      }
    }

    @media (min-width: 1336px) {
      padding: 2rem 15rem;
      margin-left: -65vw !important;
    }

    @media (min-width: 1440px) {
      padding: 2rem 15rem;
      a {
        padding: 0rem 5rem;
      }
    }

    @media (max-width: 768px) {
      margin-left: 0vw !important;
      padding: 2rem 15rem;
      a {
        padding: 0rem 5rem;
      }
    }
  }

  .dropdown-menu > a {
    display: inline !important;
  }

  .dropdown-toggle,
  .dropdown-menu {
    width: 100%;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
  }

  background-color: white !important;

  .box {
    margin-left: 3rem;
    padding-top: 1rem;
    width: 200px;
    position: relative;
    top: 0%;
    left: 0%;
    transform: none;

    img {
      max-width: 100%;
      max-height: 100%;
      display: block; /* remove extra space below image */
    }
  }
}

.siteheader-comp {
  height: 48px;
  background-color: rgba($gray-300, 0.9);
  backdrop-filter: blur(0.5rem);
  z-index: 2999;
  .site-logo {
    display: block;
    height: 48px;
    width: 48px;
    padding: 8px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .sitenav-toggle,
  .notif-toggle,
  .user-toggle {
    background-color: transparent;
    width: 48px;
  }
  .sitenav-toggle {
    font-size: 24px;
  }
}
.siteheader-buffer {
  height: 48px;
  background-color: $white;
}

.no-border-radius {
  border-radius: 0rem;
}

.btn-black:not(:disabled):not(.disabled):active,
.btn-black:not(:disabled):not(.disabled).active,
.btn-black:not(:disabled):not(.disabled):hover,
.show > .btn-richText.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-black:not(:disabled):not(.disabled):active,
.btn-black:not(:disabled):not(.disabled).active,
.btn-black:not(:disabled):not(.disabled):hover,
.show > .btn-richText.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline.btn-black {
  color: #fff;
  background: #000;
  border-color: #000;
  &:hover {
    color: #fff;
    background-color: lighten(#000, 10%);
    border-color: lighten(#000, 10%);
  }
}

// .dropdown .dropdown-menu {
//   -webkit-transition: all 0.3s;
//   -moz-transition: all 0.3s;
//   -ms-transition: all 0.3s;
//   -o-transition: all 0.3s;
//   transition: all 0.3s;

// }

// .dropdown.show .dropdown-menu { /* For Bootstrap 4, use .dropdown.show instead of .dropdown.open */
//   max-height: 300px;
//   opacity: 1;
// }

@media (max-width: 768px) {
  .navbar-brand {
    margin: 0rem !important;
  }

  .tkl-header .box {
    width: 150px !important;
    display: flex;
    margin-left: 50%;
    padding-top: 0rem;
  }
}

@media (max-width: 1024px) {
  .header-buttons {
    display: none;
  }

  .tkl-header .box {
    margin-left: 4rem;
    padding-top: 2rem;
    width: 200px !important;
    position: relative;
    top: 0%;
    left: 0%;
    transform: none;
  }
}

@media (max-width: 1440px) {
  .tkl-header .box {
    width: 200px !important;
  }
}

@media (max-width: 425px) {
  .navbar-brand {
    margin: 0rem !important;
  }

  .tkl-programs {
    float: left;
    width: 40%;
  }

  .tkl-header .box {
    width: 150px !important;
    display: flex;
    margin-left: 50%;
    padding-top: 0rem;
  }
}

.header-button-padding {
  padding: 0.7rem 0.7rem !important;
}

.tkl-header-a .nav-link .active {
  color: #dd1d45 !important;
}

@media (min-width: 1000px) and (max-width: 1025px) {
  .login-block {
    display: none;
  }
  // .tkl-header .box {
  //   margin-top: 2em;
  // }
}

.text-black-font-increase {
  font-size: 17px;
}

.cart-item-title {
  font-size: 20px;
  font-weight: 700;
}

.u-avtr,
.c-avtr,
.r-avtr,
.i-avtr {
  display: inline-block;
  padding-bottom: calc(100% - 2px);
  vertical-align: top;
  width: 100%;
  border: rem(1.7px) solid $dark;
  position: relative;
}
.u-avtr {
  .u-avtr-cred,
  .u-avtr-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $dark;
    border: rem(1px) solid $white;
    z-index: 1;
  }
  .u-avtr-img {
    z-index: 2;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  svg {
    width: 100%;
    height: 100%;
    text {
      fill: $white;
      font-weight: 300;
      font-family: $font-family-sans-serif;
      font-size: em(48px);
    }
  }
}
.c-avtr {
  .c-avtr-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $dark;
    border: rem(1px) solid $white;
    z-index: 2;
    overflow: hidden;
    background-size: cover;
    background-repeat: repeat;
    background-position: center center;
    img {
      width: 100%;
    }
  }
}
.r-avtr {
  .r-avtr-icn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    border-top: rem(2px) solid $brandAqua;
    border-right: rem(2px) solid $brandYellow;
    border-bottom: rem(2px) solid $brandBlue;
    border-left: rem(2px) solid $brandRed;
    z-index: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    color: $gray-600;
    line-height: 1;
    transform: rotate(-45deg);
    svg {
      width: 60%;
      height: 60%;
      fill: $primary;
      transform: rotate(45deg);
    }
  }
}
.i-avtr {
  // border: rem(1px) solid transparent;
  .i-avtr-icn {
    line-height: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    border: rem(1px) solid transparent;
    z-index: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    color: $black;
    svg {
      width: 60%;
      height: 60%;
      fill: $black;
    }
  }
}

/*
for css vars only.
these values are automatically known in all stylesheets.
the :root statement itself is only included in the common stylesheet.
this file is not processed by postcss when imported into the postcss-custom-properties plugin,
so only write standard css!

NOTE: for old browsers, will need to restart watcher after changing a variable
*/

/* :root {
  --fc-small-font-size: .85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: #ddd;

  --fc-button-text-color: #fff;
  --fc-button-bg-color: #2C3E50;
  --fc-button-border-color: #2C3E50;
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;

  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: #3788d8;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: rgba(255, 220, 40, 0.15);
  --fc-now-indicator-color: red;
  
  // Daygrid
  --fc-daygrid-event-dot-width: 8px;

  // List
  --fc-list-event-dot-width: 10px;
  --fc-list-event-hover-bg-color: #f5f5f5;
} */

:root {
  --fc-small-font-size: .85em;
  --fc-page-bg-color: #{$white};
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: #{$gray-300};

  --fc-button-text-color: #{$white};
  --fc-button-bg-color: #{$primary};
  --fc-button-border-color: #{$primary};
  --fc-button-hover-bg-color: #{darken($primary, 7.5%)};
  --fc-button-hover-border-color: #{darken($primary, 7.5%)};
  --fc-button-active-bg-color: #{darken($primary, 7.5%)};
  --fc-button-active-border-color: #{darken($primary, 7.5%)};

  --fc-event-bg-color: #{$info};
  --fc-event-border-color: #{$info};
  --fc-event-text-color: #{$white};
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-event-resizer-thickness: rem(8px);
  --fc-event-resizer-dot-total-width: rem(8px);
  --fc-event-resizer-dot-border-width: rem(1px);

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: #{$success};
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: rgba(255, 220, 40, 0.15);
  --fc-now-indicator-color: #{$brandRed};
  
  // Daygrid
  --fc-daygrid-event-dot-width: rem(8px);

  // List
  --fc-list-event-dot-width: rem(10px);
  --fc-list-event-hover-bg-color: #{$gray-200};
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: auto;
}
.fc .fc-daygrid-body {
  display: flex;
  height: 100%;
}
.fc .fc-scrollgrid > tbody table {
  height: 100% !important;
}
.fc .fc-view-harness {
  padding-bottom: 400px !important;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: rem(16px) !important;
}
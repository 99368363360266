.tc-comp {
  .tc-name {
    padding-left: rem(192px);
    .tc-img {
      width: rem(160px);
      height: rem(160px);
    }
  }
  .tc-name-text {
    min-height: rem(160px);
  }
  .tc-img {
    margin-top: rem(-80px);
  }
}
.pColleagues {
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    border-radius: 5rem;
  }
  .nav-pills .nav-link {
    border-radius: 5rem;
    background-color: #6c757d;
    color: white;
    
    margin: 0 5px 0 5px;
  }
  .nav-pills .nav-link.active {
    background-color: #18998e;
    color: white;
  }
  .pg-ban-comp .pg-ban-bg {
    padding-bottom: 25%;
    width: 100%;
    z-index: 0;
    overflow: hidden;
  }
  .border-right {
    border-bottom-right-radius: 25rem;
    border-top-right-radius: 25rem;
    border-color: #dedede !important;
  }
  .border-left {
    border-bottom-left-radius: 25rem;
    border-top-left-radius: 25rem;
  }
}

.item-not-selected {
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.professional_colleagues_list_wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.professional_colleague_tile_title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}

.professional_colleague_tile {
  outline: 1px solid black;
  border: 5px solid lightgray;
  border-radius: 0.5em;
  padding: 5px;
  max-width: 270px !important;
  min-width: 270px !important;
  // min-height: 210px !important;
  // max-height: 210px !important;
  min-height: 175px !important;
  max-height: 175px !important;
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 30px 20px 30px;
}
.professional_colleague_tile_details {
  // min-height: 130px !important;
  // max-height: 130px !important
  min-width: 250px !important;
  min-height: 95px !important;
  max-height: 95px !important;
  max-width: 250px !important;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  align-items: flex-start;
}

.remove_btn {
  background-color: #9f1a20;
  border: 1px solid #9f1a20;
  color: white;
  border-radius: 15px;
  min-width: 80px;
}
.remove_btn:hover {
  background-color: white;
  border: 1px solid #9f1a20;
  color: #9f1a20;
  border-radius: 15px;
}

.send_invitation_btn {
  background-color: #ab4bc6;
  border: 1px solid white;
  color: white;
  border-radius: 15px;
}
.send_invitation_btn:hover {
  background-color: white;
  border: 1px solid #ab4bc6;
  color: #ab4bc6;
}

.send_invitation_btn:focus {
  outline: none;    /* Removes the default focus outline */
  background-color: #ab4bc6;
  border: 1px solid white;
  color: white;
  box-shadow: 0 0 5px #ab4bc6;
}

/* Add styles for the active state */
.send_invitation_btn:active {
  background-color: #ab4bc6;
  border: 1px solid white;
  color: white;
}

.accept_invitation_btn {
  background-color: #0088cc;
  border: 1px solid #0088cc;
  border-radius: 15px;
  color: white;
  min-width: 80px;
}

.accept_invitation_btn:hover {
  background-color: white;
  border: 1px solid #0088cc;
  border-radius: 15px;
  color: #0088cc;
}
.decline_invitation_btn {
  background-color: #9f1a20;
  border: 1px solid #9f1a20;
  border-radius: 15px;
  color: white;
  min-width: 80px;
}
.decline_invitation_btn:hover {
  background-color: white;
  border: 1px solid #9f1a20;
  border-radius: 15px;
  color: #9f1a20;
}
.pending_invitations_btn {
  background-color: #6c757d !important;
  border: 1px solid #6c757d !important;
  border-radius: 15px;
  color: white !important;
  min-width: 100px;
}
.pending_invitations_btn:hover {
  background-color: white;
  border: 1px solid #6c757d;
  border-radius: 15px;
  color: #6c757d;
}
.memberInvitationTabHeader {
  margin-right: 10px;
}
.memberInvitationTabNotification {
  display: inline-block;
  background-color: #9f1a20;
  font-size: 14px;
  color: white;
  height: 25px;
  width: auto;
  min-width: 25px;
  padding-left: 5px;
  padding-top: 2px;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  position: relative;
  top: -20px;
  right: -20px;
}

.add_colleague_btn {
  background-color: #0088cc;
  border: 1px solid #0088cc;
  border-radius: 15px;
  color: white;
  min-width: 80px;
}

.add_colleague_btn:hover {
  background-color: white;
  border: 1px solid #0088cc;
  border-radius: 15px;
  color: #0088cc;
}
.remove_colleague_btn {
  background-color: #9f1a20;
  border: 1px solid #9f1a20;
  border-radius: 15px;
  color: white;
  min-width: 80px;
}

.remove_colleague_btn:hover {
  background-color: white;
  border: 1px solid #9f1a20;
  border-radius: 15px;
  color: #9f1a20;
}

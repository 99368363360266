.dash-t-comp {
  background-color: rgba($white, 0.9);
  backdrop-filter: blur(0.5rem);
  border-radius: rem(8px);
  height: 576px;
  .dash-t-move {
    height: rem(56px);
    font-size: rem(20px);
    line-height: 1;
  }
  .dash-t-body {
    .tab-content {
      flex-grow: 1;
    }
    .tab-pane {
      display: flex;
      height: 100%;
    }
  }
  .t-avtr-wrap {
    width: rem(48px);
    height: rem(48px);
    left: rem(8px);
    top: rem(8px);
  }
  .t-row-name {
    min-height: rem(64px);
  }
  .t-notif-wrap {
    width: rem(24px);
    height: rem(24px);
    right: rem(16px);
    top: 50%;
    margin-top: rem(-12px);
  }
  .dash-t-cpgrs {
    width: rem(64px);
    height: rem(64px);
  }
  .fc .fc-scroller-harness-liquid, .fc .fc-scrollgrid > tbody table {
    height: 394px !important;
  }
}
.pg-ban-comp {
  margin: rem(0 -8px);
  position: relative;
  .pg-ban-bg {
    position: absolute;
    padding-bottom: 32.25%;
    border-radius: rem(0 0 8px 8px);
    width: 100%;
    z-index: 1;
    overflow: hidden;
    background-size: cover;
    background-repeat: repeat;
    background-position: center center;
    img {
      @include center-center();
      width: 100.5%;
    }
  }
  .pg-ban-buf {
    padding-bottom: 21.5%;
    position: relative;
    z-index: 2;
  }
}

.tc-comp {
  .tc-name-wrap {
    width: 66.66%;
  }
  .tc-right-one {
    width: 33.34%;
  }
  .tc-name {
    padding-left: rem(96px);
    .tc-img {
      width: rem(80px);
      height: rem(80px);
    }
  }
  .tc-name-text {
    min-height: rem(80px);
  }
  .tc-img {
    margin-top: rem(-40px);
  }
  .min-md-25 {
    min-width: 25%;
  }
}
.position-absolute {
  position: absolute !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.border {
  border: 1px solid #dedede !important;
}
.text-center {
  text-align: center !important;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.cta-comp.no-text {
  padding-left: 0;
  padding-right: 0;
  width: 2.375rem;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
.cta-comp {
  position: relative;
}

.pg-ban-comp .pg-ban-buf {
  padding-bottom: 21.5%;
  position: relative;
  z-index: 2;
}
@media (min-width: 46.375em) {
  .pg-ban-comp {
    margin: 0 -1rem;
  }
}
.pg-ban-comp {
  margin: 0 -0.5rem;
  position: relative;
}
.pg-ban-comp .pg-ban-bg {
  position: absolute;
  padding-bottom: 32.25%;
  border-radius: 0 0 0.5rem 0.5rem;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  background-size: cover;
  background-repeat: repeat;
  background-position: center center;
}

// .pg-ban-bg {
//     background-image: url("https://image.shutterstock.com/image-photo/waves-water-river-sea-meet-600w-1529923664.jpg");
// }
@media (min-width: 46.375em) {
  .pg-ban-comp .pg-ban-bg {
    border-radius: 0 0 1rem 1rem;
  }
}
#input-trigger {
  visibility: hidden;
}

body .rounded-xl {
  border-radius: 0.5rem !important;
}

.u-avtr,
.c-avtr,
.r-avtr,
.i-avtr {
  display: inline-block;
  padding-bottom: calc(100% - 2px);
  vertical-align: top;
  width: 100%;
  border: 0.0625rem solid #2a2a2a;
  position: relative;
}
.c-avtr .c-avtr-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2a2a2a;
  border: 0.0625rem solid #fff;
  z-index: 2;
  overflow: hidden;
  background-size: cover;
  background-repeat: repeat;
  background-position: center center;
}

body .rounded-xl {
  border-radius: 0.5rem !important;
}
.image-background {
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
}
.img-background {
  height: 100%;
}
.modal-dialog {
  //max-width: 500px;
  //margin: 15% auto;
}
.select-box-style {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
  text-overflow: ellipsis;
  .select-box-style__control {
    border-radius: 50rem !important;
    height: calc(1.5em + 0.5rem + 2px);
    font-size: 0.875rem;
    line-height: 1.5;
    min-height: 0px !important;
    .select-box-style__value-container--has-value {
      bottom: 5%;
    }
    .select-box-style__control--menu-is-open {
      height: 20px;
    }
  }
  .select-box-style__multi-value {
    background-color: darkseagreen;
    line-height: normal;
  }
  .select-box-style__indicator,
  .select-box-style__indicator-separator {
    display: none;
  }
}
.btn-style-see-all {
  padding-top: 0px;
  padding-bottom: 0px;
}
.form-group {
  margin-bottom: 1rem;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.button-align-in-input1 {
  position: fixed;
  margin-top: 0.2rem !important;
  svg {
    fill: darken(#1dbaad, 7.5%); //E15639
  }
}
.button-align-in-input11 {
  position: fixed;
  margin-top: 0.2rem;
  svg {
    fill: darken(#1dbaad, 7.5%); //E15639
  }
}
.button-align-in-input2 {
  position: fixed;
  margin-top: 1.25rem !important;
  svg {
    fill: #e15639;
  }
}
.button-align-in-input22 {
  position: fixed;
  margin-top: 1.25rem !important;
  svg {
    fill: #e15639;
  }
}
.class-styles {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.modal-styles {
  .close {
    outline: 0;
  }
}

.btn-background-style-save {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  text-decoration: none;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.create-course-validation-error-field-styles {
  margin-top: 25px;
}
.img-style {
  height: 10em;
  display: block;
  margin: auto;
}

.search-results-div {
  //border: 2px solid blue;
  margin-top: 0.05em;
  max-height: 200px;
  overflow: auto;
}
.options-padding {
  margin-left: 0.3em;
}

.error-field {
  border: 2px solid #dc3545;
}
.input-width-style {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  caret-color: transparent;
}
.categories-style {
  padding-bottom: 0.5em;
}
.tags-style {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 0.2em;
}
.search-results-div-categories {
  margin-top: 0.05em;
  max-height: 120px;
  overflow: auto;
}
.search-results-div-tags {
  margin-top: 0.05em;
  max-height: 110px;
  overflow: auto;
}
.modal-styles-tags-categories {
  .modal-header {
    padding: 0.5rem 0.5rem;
  }
  .close {
    outline: 0;
  }
}
.error-field-programs {
  // font-size: 20px;
}
.input-limit-in-input {
  textarea {
    padding-right: 50px;
    overflow: hidden;
    // &.focus::after{
    //     row:1;
    // }
  }
  // textarea::placeholder {
  //     line-height: 2.8em;
  // }
}
.validation-color-description {
  color: #dc3545;
}
.div-container {
  .resize-flex-prpoerties {
    flex: 0 0 85% !important;
    max-width: 95%;
  }
}
@media (min-width: 46.375em) {
  // .max-wrap {
  //     max-width: 100%;
  //     margin: 0 auto;
  // }
}
@media (min-width: 2500px) {
  //large-devices
  // .large-devices-resize {
  //     margin-left: 2em;
  //     padding-right: 10rem !important;
  // }
}
// @media (min-width: 73.25em){
// .col-xl-8 {
//     flex: 0 0 100%;
//     max-width: 70.66667%;
// }
// }

@media (min-width: 1160px) {
  .col-xl-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.save-as-draft-style {
  color: darken(#1dbaad, 7.5%);
  margin-top: 0.5em;
  margin-left: 1em;
}
.default-img-width {
  &.avatar {
    max-width: 13em !important;
  }
  &.icon {
    max-width: 4em !important;
  }
}

.pg-main {
  .max-wrap {
    max-width: inherit;
    margin: 0 auto;
  }
}
// position: absolute;
// top: -0.23em;

.course-Objective-styles {
  .courseObjective {
    // border-radius: 20px;
    resize: none;
  }
  .glyphicon {
    position: relative;
    svg {
      position: absolute;
    }
  }
}
@-moz-document url-prefix() {
  #courseObjective-styles-id {
    .input-limit-in-input {
      textarea {
        height: 300px !important;
        z-index: 10;
      }
      .glyphicon {
        svg {
          top: -0.25em;
        }
      }
    }
  }
}
.content-style {
  .create-course-card {
    padding: 1em;
    padding-right: 2.5em;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.btn-save-width {
  width: 85px;
}

.btn-cancel-width {
  width: 85px;
}

.show-txt-error {
  color: #dc3545;
  font-weight: 600;
}
.input-caret {
  caret-color: auto;
  white-space: normal;
}

#avtr-container {
  .isRound-container {
    border-radius: 50% !important;
    .isRound-div {
      border: none !important;
      background: none !important;
      .isRound-img {
        background-size: cover !important;
        border-radius: 50% !important;
      }
    }
  }
}

.showInitials {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  // background: darken(#0088cc, 7.5%) !important;
  font-size: calc(100px - 20px);
  color: #fff;
  text-align: center;
  align-items: center;
  display: grid;
  background-image: url("../../images.png") !important;
}
@media (max-width: 650px) {
  .showInitials {
    font-size: calc(100px - 60px);
  }
}

.description_editor {
  .tox-tinymce {
    @media (max-width: 600px) {
      height: 500px !important;
    }
  }
}

.profile-creation-page-styles {
  .pg-ban-comp {
    .pg-ban-bg {
      background-color: #acaeb0 !important;
    }
    .pg-ban-buf {
      padding-bottom: 30% !important;
      @media (max-width: 1200px) {
        padding-bottom: 28.8% !important;
      }
      @media (max-width: 800px) {
        padding-bottom: 26.8% !important;
      }
      @media (max-width: 500px) {
        padding-bottom: 26% !important;
      }
    }
  }
}

$min_width: 300px;
$max_width: 1600px;
$min_font: 15.5px;
$max_font: 17.5px;
$mod_1: 1.1; // mobile
$mod_2: 1.5; // desktop

html, body {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

h1, .h1 {  
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2, .h2 {  
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3, .h3 { 
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}
h4, .h4 { 
  font-size: $mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1 *$min_font, $mod_2 *$min_font);
}
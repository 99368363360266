.tc-comp {
  .tc-main {
    background-color: rgba($white, 0.9);
    backdrop-filter: blur(0.2rem);
    position: relative;
    z-index: 4;
    border-radius: rem(8px);
  }
  .tc-name {
    padding-left: rem(56px);
    .tc-img {
      width: rem(48px);
      height: rem(48px);
    }
  }
  .tc-name-text {
    min-height: rem(48px);
  }
  .tc-img {
    top: 50%;
    margin-top: rem(-24px);
  }
  .tc-toggle-btn {
    width: 100%;
    background-color: transparent;
    position: relative;
    text-align: left;
    border: rem(1px) solid $brandBlue;
    color: $brandBlue;
    div {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      font-size: rem(24px);
    }
  }
}

.profile-banner-styles {
  .pg-ban-comp {
    .pg-ban-buf {
      padding-bottom: 32% !important;
    }
  }
}

@import './imports';
@import './sass/base';
@import './sass/typography';
@import './sass/fullcalendar';
@import './sass/ie11';


.default-page-list {
  .page-list {
    columns: 2;
  }
  a {
    display: block;
    padding: 10px;;
  }
}
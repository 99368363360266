.read_notification {
  padding: 0.5rem;
  color: #adadad;
  &:hover {
    color: darken(#1dbaad, 7.5%);
    background-color: darken(#dedede, 10%);
  }
  .data_color {
    color: "#6c6c6c";
  }
}

.unread_notification {
  padding: 0.5rem;

  &:hover {
    color: darken(#1dbaad, 7.5%);
    background-color: darken(#dedede, 10%);
  }
  .data_color {
    color: "#6c6c6c";
  }
}
.html_parse_p_stlyes {
  p {
    margin-bottom: 0px;
  }
}

@import "./sass/rem";
@import "./sass/em";
@import "./sass/colors";

$primary-dark: darken($primary, 10%);
$secondary-dark: darken($secondary, 10%);

$primary-light: lighten($primary, 10%);
$secondary-light: lighten($secondary, 10%);

$grays: ();
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "brandred": $brandRed,
    "brandblue": $brandBlue,
    "brandyellow": $brandYellow,
    "brandaqua": $brandAqua,
  ),
  $theme-colors
);

$grid-breakpoints: (
  xs: 0,
  sm: em(572px),
  md: em(742px),
  lg: em(992px),
  xl: em(1172px),
  xxl: em(1632px) // xxl: em(1696px),,,,,
);

$container-max-widths: (
  sm: rem(540px),
  md: rem(720px),
  lg: rem(960px),
  xl: rem(1140px),
  xxl: rem(1600px),
);

$grid-columns: 12;
$grid-gutter-width: rem(16px);
$grid-row-columns: 6;

$border-radius: rem(4px);
$border-radius-lg: rem(4px);
$border-radius-sm: rem(4px);

$font-family-sans-serif: "Roboto Condensed", sans-serif;

$enable-responsive-font-sizes: true;

$xs: map-get($grid-breakpoints, "xs");
// Small devices (landscape phones, 576px and up)
$sm: map-get($grid-breakpoints, "sm");
// Medium devices (tablets, 768px and up)
$md: map-get($grid-breakpoints, "md");
// Large devices (desktops, 992px and up)
$lg: map-get($grid-breakpoints, "lg");
// Extra large devices (large desktops, 1200px and up)
$xl: map-get($grid-breakpoints, "xl");
// Extra large devices (large desktops, 1696px and up)
$xxl: map-get($grid-breakpoints, "xxl");

$spacer: rem(16px);
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
    6: (
      $spacer * 3.25,
    ),
    7: (
      $spacer * 3.5,
    ),
    8: (
      $spacer * 4,
    ),
    9: (
      $spacer * 4.5,
    ),
    10: (
      $spacer * 5,
    ),
  ),
  $spacers
);

$breadcrumb-divider: "\279C";

$enable-negative-margins: true;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "./sass/mixins";

@media all and (-ms-high-contrast:none) {
  .ie-w-100 {
    width: 100%;
  }

  /* IE10 */
  *::-ms-backdrop,
  .ie-w-100 {
    width: 100%;
  }

  /* IE11 */
}
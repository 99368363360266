@media (min-width: 755px) {
  .cohorts-page-styles {
    margin-left: 3.2em;
  }
}
.cohorts-page-styles {
  overflow: auto;
  .publish-row-styles {
    //border: 1px solid ;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.8);
    margin: auto;
    width: 95%;
  }
  background-color: lightblue;
  height: inherit;
  .Course-Publishing {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 25px;
    font-weight: 500;
    font-family: system-ui;
    padding-top: 0.5em;
  }
  .course_items_flex {
    display: inline-flex;
    width: inherit;
    //border-top: 1px solid;
    //border-radius: 30px;
    height: 80px;
    .input_field {
      width: auto;
      width: calc(100% - 50%);
      flex-grow: 1;
      padding: 2em;

      // border-left: 1px solid;
      .react-datetime-picker {
        left: -2em;
        .react-datetime-picker__calendar {
          .react-calendar {
            width: 300px;
            max-width: 100%;
          }
        }
        .react-datetime-picker__wrapper {
          border: none;
          .react-datetime-picker__inputGroup {
            input {
              &:focus {
                outline: none !important;
              }
            }
            .react-datetime-picker__inputGroup__input--hasLeadingZero {
              padding-left: 0em;
            }
          }
          .react-datetime-picker__clear-button {
            display: none;
          }
          .react-datetime-picker__calendar-button {
            &:focus {
              outline: none !important;
            }
            .react-datetime-picker__calendar-button__icon {
              width: 1.2em;
              margin-top: -0.2em;
              stroke: #5a6268;
              :hover {
                stroke: darken(#1dbaad, 7.5%);
              }
              :focus {
                border: none;
                outline: none;
              }
            }
          }
        }
      }
    }

    .input_field input {
      margin-left: -2em;
      margin-top: -0.5em;
      &:hover {
        margin-left: -2em;
        margin-top: -0.5em;
      }
      &:disabled {
        //margin-left: -1.3em;
        margin-top: -0.5em;
      }
    }

    .input_field input[type="number"] {
      margin-left: -2em;
      margin-top: -0.5em;
    }
  }

  .publish-row-styles {
    .input_field {
      padding-right: 2.7em;
    }
  }

  label {
    padding: 2em 0em 0em 1em;
    font-size: 14px;
  }
}
.creating-cohorts {
  margin-top: 2em;

  padding-bottom: 2em;
  // margin-left: 0.8em;
  .cohorts-heading {
    font-size: 20px;
    font-weight: 600;
    // margin-left: 1em;

    margin-left: 4%;
  }
  .cohort-row {
    margin: 0em;
    width: 95%;
    margin: auto;
  }
  .publish-btn-style {
    margin: 1em 0em 2em 2em;
  }
  .cohort-right-bar {
    background: #fff;
    border-radius: 30px;
    height: 100%;
    border-left: 10px solid lightblue;
    border-right: 10px solid lightblue;
  }
  .cohort-left-bar {
    background: #fff;
    border-radius: 30px;
    height: 100%;
    border-left: 10px solid lightblue;
    border-right: 10px solid lightblue;
  }
}

@media (max-width: 950px) {
  .cohort-left-bar {
    margin-top: 2em;
  }
}
.show-border-error {
  border: 2.8px solid #dc3545;
}
.cohorts-multiselect {
  // ::-webkit-scrollbar {
  //   width: 10px;
  // }

  /* Track */
  // ::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 10px #dedede;
  //   border-radius: 10px;
  // }

  // /* Handle */
  // ::-webkit-scrollbar-thumb {
  //   background: darken(#1DBAAD, 7.5%);
  //   border-radius: 10px;
  // }

  // /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: #60c2ba;
  // }

  .input-element {
    padding-top: 0px !important;
    margin: 1.6em 4.5em 2em 0.2em;
    // text-align: center;
    line-height: 26px;
    cursor: pointer;
    .keyboard_arrow_style {
      float: right;
      font-size: 14px;
      font-weight: bolder;
      line-height: 35px;
    }
    .search-results-div {
      background-color: #fff;
      padding: 1em;
      // position: relative;
      position: absolute;
      max-height: 200px;
      overflow: auto;
      border: 2px solid #dedede;
      //width: 105%;
      // right: 10px;
      right: 0px;
      padding-top: unset;
      border-radius: 10px;
      padding-bottom: unset;
      //change
      // width: calc(100% - -20px);

      width: calc(100% - 0px);
      z-index: 100;
      .cohorts-page-styles-label {
        padding: 0.2em !important;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}

// @media (min-width: 750px) {
//   .cohorts-multiselect {
//     .input-element {
//       .search-results-div {
//         width: 112%;
//       }
//     }
//   }
// }
// @media (max-width: 745px) {
//   .cohorts-multiselect {
//     .input-element {
//       .search-results-div {
//         width: 105%;
//       }
//     }
//   }
// }
// @media (max-width: 425px) {
//   .cohorts-multiselect {
//     .input-element {
//       .search-results-div {
//         width: 110%;
//       }
//     }
//   }
// }
// @media (min-width: 1050px) {
//   .cohorts-multiselect {
//     .input-element {
//       .search-results-div {
//         width: 106%;
//       }
//     }
//   }
// }
.input-element-multi-select-dropdown {
  padding: unset !important;
  position: relative;
  top: 2em;
  margin: 0em 1em 0em 1em;
  padding-top: 0.3em !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
  margin-top: -0.6em;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 10px !important;
  white-space: nowrap;
}
.input-element-multiSelect {
  position: absolute;
  top: 7.8em;
  right: 1.5em;

  .search-results-div {
    background-color: #fff;
    padding: 1em;
    position: relative;
    max-height: 200px;
    overflow: auto;
    border: 2px solid #dedede;
    //width: 105%;
    right: 10px;
    padding-top: unset;
    border-radius: 10px;
    padding-bottom: unset;
    z-index: 100;
    .cohorts-page-styles-label {
      padding: 0.2em !important;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
.keyboard_arrow_style {
  float: right;
  font-size: 14px !important;
  font-weight: bolder !important;
}
.input-element-multiselect-nodropdown {
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: -1.5em;
  //width: 100% !important;
  white-space: nowrap;
  margin-top: 0.15em;
  margin-left: -1.2em;
}
@media (max-width: 400px) {
  .input-element {
    .keyboard_arrow_style {
      top: -35px;
      position: relative;
      left: 5px;
      margin-left: 20px;
    }
  }
}
.input-field-box-style {
  width: 10px !important;
}

.add-padding-to-container {
  padding-bottom: 1.45em;
}
@media (max-width: 850px) {
  .add-padding-to-container {
    padding-bottom: 1.2em;
  }
}
.input-element-multiSelect {
  width: calc(100% - 230px);
}
.show-border-error-border-radius {
  border: 2.8px solid #dc3545;
  border-radius: 30px;
}
.span-text-styles {
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: -1.3em;
  vertical-align: text-top;
}

#publish-text {
  margin-left: 1.8em;
  font-size: 20px;
  color: rgb(19, 120, 111);
  display: inline-block;
}
.numberBar-scrolling {
  input[type="number"]::-webkit-inner-spin-button {
    opacity: 0;
  }
  &:hover {
    input[type="number"]::-webkit-inner-spin-button {
      opacity: 1;
    }
  }
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
    &:hover {
      -moz-appearance: unset;
      appearance: unset;
      margin: 0;
    }
  }
}

.select-container-manage-cohorts {
  position: relative;
  .select-arrow-icon {
    font-weight: bold;
    outline: none;
    position: absolute;
    right: 0.5em;
    font-size: 1.5em;
    top: 0.1em;
    pointer-events: none;
  }
  .icon-hide {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; /* Remove default arrow */
    cursor: pointer;
  }
}

.cohort-left-bar {
  .numberBar-scrolling {
    .input_field {
      padding-top: 1.5em;
    }
  }
}

.cohort-content-margin {
  margin: auto;
  width: 95%;
}
.cohorts-page-styles {
  .course_items_flex {
    .input_field {
      position: relative;
      .keyboard_arrow_style {
        position: absolute;
        right: 12px;
        //top: 5px;
      }
    }
    .input_field_date input[type="number"] {
      margin-left: 0em;
    }
  }
}

.cohorts-multiselect {
  .input_field_multiSelect {
    max-width: calc(100% - 230px);
    margin-left: 0;
    &.input-element-multi-select-dropdown {
      .input-element-multiselect-nodropdown {
        margin-left: -0.5em;
      }
    }
  }
  .input-element-multiSelect {
    width: calc(100% - 240px);
    right: 3.5em;
  }
}

.dollar {
  position: relative;
}
.dollar input {
  padding-left: 15px;
}
.dollar:before {
  position: absolute;
  content: "$";
  left: -20px; //not needed
  top: 4px;
  vertical-align: middle;
}
.currency {
  padding-left: 1em;
}

.cohorts-page-styles .creating-cohorts .featured_course_class {
  margin-left: 4%;
  margin-top: 0.8em;
  label {
    padding: 0;
    font-size: 16px;
    font-weight: 600;
  }
}
@media (max-width: 600px) {
  .cohorts-page-styles .creating-cohorts .featured_course_class {
    margin-left: 5%;
  }
}
@media (min-width: 1500px) {
  .cohorts-page-styles .creating-cohorts .featured_course_class {
    margin-left: 3.2%;
  }
}
.publish_cohort_featured_course_container {
  display: flex;
  .featured_course_class {
    label {
      margin-top: 0.8em;
    }
  }
}
.publish-row-styles {
  &.catalogue_description_adding {
    display: flex;
    margin-top: 2em;
    padding-bottom: 2em;
    label {
      align-self: center;
      font-size: 18px;
      padding-top: 0.5em;
      margin-left: -0.8em;
    }
    .container-style {
      max-width: calc(100% - 90px);
      margin-left: 1em;
    }
    .featured_course_class {
      margin-left: 1%;
      position: relative;
      z-index: 1000;
      label {
        font-weight: 600;
      }
    }
  }
}

label {
  font-weight: 600;
}

.update_cohort_multiselect {
  .multi-select-position-title {
    position: relative;
    .add-padding-to-container {
      .input_field_multiSelect {
        margin-top: -2em !important;
        margin-bottom: 20px;
        .input-element-multiselect-nodropdown {
          margin-top: 0em !important; //span label
          margin-left: -0.25em !important;
        }
      }
      .input-element-multiSelect {
        position: absolute;
        top: 65px;
        right: 0;
        left: 0;
        margin-left: 15px;
        width: inherit;
        @media (min-width: 1150px) {
          top: 70px;
        }
      }
      .cohorts-multiselect {
        .input-element-multi-select-dropdown {
          width: initial;
          max-width: 100% !important;
          width: 100% !important;
        }
      }
    }

    .cohorts-multiselect {
      margin-top: -1.2em;
      .input-element {
        margin-top: 0px;
      }
      .input_field_multiSelect {
        width: initial;
        max-width: 100% !important;
        width: 100% !important;
        margin-bottom: 10px;
        .input-element-multiselect-nodropdown {
          margin-left: 0.25em;
        }
      }
    }
  }
  .selectdropdown_update_cohort {
    .cohorts-multiselect {
      margin-top: -1.2em;
    }
  }
}

.update_cohorts_flex_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.infiniteScroll_parent::-webkit-scrollbar {
  display: none;

  // background: red;
}
.infiniteScroll_parent::-webkit-scrollbar-track {
  margin-right: 20px;
  // background: red;
}

.infiniteScroll_child_with_search::-webkit-scrollbar-track {
  margin-bottom: 100px;
}

.infiniteScroll_child_1::-webkit-scrollbar-track {
  margin-bottom: 55px;
}
.infiniteScroll_child_2::-webkit-scrollbar-track {
  margin-bottom: 5px;
}

.infiniteScroll_child_3::-webkit-scrollbar-track {
  margin-bottom: 0px;
}

.infiniteScroll_parent {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

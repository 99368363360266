.Profile-tabs {
  display: flex;
  border-bottom: 2px solid #ddd;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
}

.Profile-tabs .tab {
  flex: 1;
  text-align: center;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  border: none;
  background-color: #efefef;
  color: #000;
  transition: all 0.3s ease;
  border-bottom: 2px solid transparent;
}

.Profile-tabs .tab:first-child {
  border-radius: 5px 0 0 5px;
}

.Profile-tabs .tab:last-child {
  border-radius: 0 5px 5px 0;
}

.Profile-tabs .tab.active {
  background-color: rgb(26, 159, 147);
  color: #fff;
  border-bottom: 2px solid rgb(26, 159, 147);
}

.Profile-tabs .tab:hover {
  background-color: rgb(26, 159, 147);
  color: #fff
}

.Profile-tabs .tab span.material-icons {
  vertical-align: middle;
  margin-right: 8px;
  font-size: 18px;
}

.user-profile-create {
  
  .location-fields {
    display: flex;
    justify-content: space-between;
    .text_fields {
      width: 100%;
      margin-right: 0.5em;
      position: relative;
      .multiple-fields {
        margin-top: 0.8em;
        margin-left: 2em;
      }
    }
    .input_field {
      margin-top: 0;
      margin-bottom: 0;
      .span-text-styles {
        margin-left: 0em;
      }
      .search-results-div {
        position: absolute;
        left: 0;
        right: 0;
      }
    }
    .multiple-fields-dropdown {
      margin-top: 0.8em;
      margin-left: 2em;
    }
  }
  .multiple-select,
  .multiple-select2,
  .multi-select-position-title {
    position: relative;
    .add-padding-to-container {
      .input_field_multiSelect {
        margin-top: -2em !important;
        margin-bottom: 20px;
        .input-element-multiselect-nodropdown {
          margin-top: 0em !important; //span label
          margin-left: -0.25em !important;
        }
      }
      .input-element-multiSelect {
        position: absolute;
        top: 65px;
        right: 0;
        left: 0;
        margin-left: 15px;
        width: inherit;
        @media (min-width: 1150px) {
          top: 70px;
        }
      }
      .cohorts-multiselect {
        .input-element-multi-select-dropdown {
          width: initial;
          max-width: 100% !important;
          width: 100% !important;
        }
      }
    }

    .cohorts-multiselect {
      margin-top: -1.2em;
      .input_field_multiSelect {
        width: initial;
        max-width: 100% !important;
        width: 100% !important;
        margin-bottom: 10px;
        .input-element-multiselect-nodropdown {
          margin-left: 0.25em;
        }
      }
    }
  }

  .multiple-select2 {
    .add-padding-to-container {
      .input-element-multiSelect {
        @media (min-width: 1150px) {
          top: 95px;
        }
        top: 90px;
      }
    }
  }
  .singleDropdown-label-control-align {
    .cohorts-multiselect {
      margin-top: -1.2em;
      .input-element {
        margin-bottom: 0px;
      }
    }
  }
  .singleDropdown-state-label-control-align {
    .cohorts-multiselect {
      margin-top: -1.5em;
      .input-element {
        margin-bottom: 0px;
      }
    }
  }

  .cohorts-multiselect .input-element .search-results-div {
    width: calc(100% - 15px);
    right: 5px;
  }
}
// .input_field {
//   margin-top: 0;
//   .span-text-styles {
//     margin-left: 0;
//   }
// }
.input_field_profile_page {
  margin-top: 0 !important;
  .span-text-styles {
    margin-left: 0;
  }
}

.profile-creation-page-styles {
  .pg-ban-comp {
    .pg-ban-bg {
      background-color: #acaeb0 !important;
    }
    .pg-ban-buf {
      // @media (min-width: 1600px) {
      //   padding-bottom: 30% !important;
      // }

      padding-bottom: 30% !important;
      @media (max-width: 1200px) {
        padding-bottom: 28.8% !important;
      }
      @media (max-width: 800px) {
        padding-bottom: 26.8% !important;
      }
      @media (max-width: 500px) {
        padding-bottom: 26% !important;
      }
    }
    // .pg-ban-buf {
    //   .position-absolute {
    //     top: 150px;
    //     bottom: unset !important;
    //   }
    // }
  }
}
.keepItPv-align {
  margin-left: 0.5em;
}


.avatr-in-banner-container {
  position: relative;

  .avtr-image {
    position: absolute;
    bottom: 12px;
    left: 8px;
  }
  .pg-ban-comp {
    .pg-ban-bg {
      border-radius: 20px;
      min-width: 300px;
    }
  }
}

@media (min-width: 600px) {
  .profile-info-styles {
    width: calc(100% - 200px);
  }
}

.profileData-aboutYourself {
  // overflow: auto;
  min-height: 25rem;
  background-color: #fff;
  border-radius: 10px;
  .about_yourself {
    width: 100%;
    height: auto;
    display: inline-block;
    overflow-x: auto;
  }
}
@media (min-width: 850px) {
  .profileData-aboutYourself {
    .align-container-with-following {
      // height: 0px;
      // min-height: 100%;
    }
  }
}
// .pwd_field_icon_profile {
//   position: relative;
//   .password-icon-visible {
//     position: absolute;
//     right: 10px;
//     bottom: 2px;
//   }
// }
.review-hover {
  background-color: "#fff";
  color: "#18998e";
  &:hover {
    background-color: "#18998e";
    color: "#fff";
  }
}

.pwd_field_change_pwd_profileEdit {
  position: relative;
  .password-icon-visible {
    position: absolute;
    right: 10px;
    top: 2px;
    font-size: 20px;
    cursor: pointer;
  }
}
@media (max-width: 750px) and (min-width: 600px) {
  .slick-course-name {
    margin-top: 2em;
  }
}

.user_bio_data_font_size {
  font-size: 18px;
  color: #212121;
  font-weight: 400 !important;
  line-height: 1.5;
}
.btn-msg {
  ._pi {
    margin-top: 0.1em;
    padding-left: 0.8em;
    margin-right: 0.2em;
  }
  display: inline-flex;
  flex-direction: row-reverse;
}
.slick-slider .slick-track,
.slick-slider .slick-list .slic-track {
  float: left;
}

.select-dropdown-input-search-styles {
  z-index: 100;
  position: relative;
  width: calc(100% + 20px);
  border: none;
  border-bottom: 2px solid;
  margin-left: -0.6em;
  margin-top: 0.5em;
}

// margin: 0.5em;
// position: absolute;
// top: 155px;
// display: flex;
// justify-content: flex-end;
// z-index: 1000;
// width: 100%;
// background: #fff;
// /* margin-bottom: 0; */
// right: 2px;
.profile-container {
  .c-avtr-img {
    img {
      object-fit: cover;
    }
  }
  .pg-ban-comp .pg-ban-bg {
    position: relative;
    padding: 0px;
    img {
      object-fit: fill;
      // width: auto;
      transform: none;
      position: static;
      margin: auto;
    }
  }
}

.user_data_overflow_wrap {
  max-width: 100%;
  overflow: auto;
}

.container_suggest {
  background-color: #fff;
  border-radius: 10px;
  overflow: auto;
  max-height: 100%;
  @media (min-width: 700px) {
    position: absolute;
    right: -4px;
    // max-height: 450px;
  }
  @media (max-width: 700px) {
    margin-top: 1em;
    // max-height: 420px;
  }

  @media (min-width: 1500px) {
    // max-height: 450px;
  }
}

.siteheader-comp {
  height: 48px;
  background-color: rgba($gray-300, 0.9);
  backdrop-filter: blur(0.5rem);
  z-index: 2999;
  .site-logo {
    display: block;
    height: 48px;
    width: 48px;
    padding: 8px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .sitenav-toggle,
  .notif-toggle,
  .user-toggle {
    width: 48px !important;
    position: relative;
  }
  .sitenav-toggle {
    font-size: 24px;
  }

  .dropdown-item {
    border-bottom: 1px solid #6c6c6c;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: rgb(129, 126, 126);
    text-decoration: none;
    background-color: transparent;
    border-bottom: 1px solid #dedede;
  }

  .border-bottom {
    border-bottom: 1px solid #6c6c6c !important;
  }
  .dropdown-item:hover {
    color: darken(#1dbaad, 7.5%);
    background-color: darken(#dedede, 10%);
  }
  .card {
    border: 0px !important;
  }

  .dropdown-menu.show {
    overflow: auto;
    // left: 2rem !important;
    padding: 2rem 0rem;
    padding-bottom: 0em;
    border-radius: 1rem;
    border: 0px;
    width: 500px;
  }

  .header-notification-dropdown {
    .dropdown-toggle::after {
      top: -50px;
      position: relative;
      content: none;
      border: none;
    }
  }
  .dropdown-toggle::after {
    top: -50px;
    position: relative;
  }

  .list-group-item {
    border: 0 !important;
    border-radius: 15px;
  }

  .list-group-item:hover {
    background-color: #e9e9e9;
    color: black;
  }

  .card {
    left: 3vw;
    height: 0px;
    width: 0px;
  }

  .arrow-top {
    width: 0;
    height: 0;
    position: inherit;
    top: 5px;
    right: 7.5rem;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid white;
  }

  .btn-notification {
    background-color: transparent;
    border-bottom: 1px solid transparent;
    border-color: transparent;
    color: transparent;
    font-size: xx-large;
    position: relative;

    right: 1rem;
    height: 40px;
    &:hover {
      color: transparent;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .btn-notification:not(:disabled):not(.disabled):active,
  .btn-notification:not(:disabled):not(.disabled).active,
  .show > .btn-notification.dropdown-toggle {
    color: transparent;
    background-color: transparent;
    border-color: transparent;
    box-shadow: 0 0 0 0.2rem transparent;
  }

  .btn-notification:focus,
  .btn-notification.focus {
    color: transparent;
    background-color: transparent;
    border-color: transparent;
    box-shadow: 0 0 0 0.2rem transparent;
  }

  .btn-notification:not(:disabled):not(.disabled):active:focus,
  .btn-notification:not(:disabled):not(.disabled).active:focus,
  .show > .btn-notification.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem transparent;
  }
}
.siteheader-buffer {
  // height: 58px;
  background-color: $white;
}

.modal-header {
  .modal-title {
    font-weight: 600;
  }
}

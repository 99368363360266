


.box.small{
    height: 100px;
}

.navbar-brand{
    margin: 0rem;
}

.tkl-header {
    
    padding: 1.5rem 0rem 0rem 0rem !important;
   
}







.tkl-about-a{
    display: inline-block !important;
}

.navbar-nav .nav-link {
    
    padding-left: 1rem !important;
}
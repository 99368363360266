.tkl-header #basic-navbar-nav {
    float: none !important;
    position: relative !important;
    top:0% !important;
    left: 0% !important;
    transform:none !important;
}

.tkl-header .box {
    width: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
body {
  background-color: $gray-900;
  backface-visibility: hidden;
  letter-spacing: 0.05rem;
}
.max-wrap {
  max-width: rem(1920px);
  margin: 0 auto;
}

.top-0 {
  top: 0 !important;
}
.right-0 {
  right: 0 !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.left-0 {
  left: 0 !important;
}
.word-wrap {
  @include word-wrap();
}
.pg-main {
  background-color: $gray-300;
  @media (min-width: $md) {
    padding-left: 48px;
  }
}
.dd_colorpicker {
  box-shadow: none !important;
  input {
    display: none !important;
  }
}
.line-height-1 {
  line-height: 1;
}
body {
  &.nav-open {
    overflow: hidden;
  }
  .scroll-comp {
    height: 100%;
    & > .custom-scroll-box {
      padding-right: rem(24px);
      overflow-x: hidden !important;
    }
    .track-vertical {
      background-color: $gray-200;
      border-radius: rem(8px);
      height: 100%;
      position: absolute;
      right: rem(0px);
      width: rem(8px);
      z-index: 1;
    }
    .thumb-vertical {
      background-color: $gray-800;
      border-radius: rem(8px);
      width: rem(8px);
      z-index: 2;
    }
    ::-webkit-scrollbar {
      width: rem(8px);
    }

    ::-webkit-scrollbar-track {
      background-color: $gray-200;
      border-radius: rem(8px);
    }

    ::-webkit-scrollbar-thumb {
      border-radius: rem(8px);
      background-color: $gray-800;
    }
  }
  .material-icons {
    font-size: inherit;
    line-height: inherit;
    vertical-align: top;
    display: inline-block;
    font: normal normal normal 24px/1 "Material Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    font-display: swap;
  }
}
body {
  @keyframes rotating {
    100% {
      transform: rotate(360deg);
    }
  }

  .ld-spinner {
    border-radius: 50%;
    width: rem(40px);
    height: rem(40px);
    border: rem(4px) solid rgba(0, 0, 0, 0.1);
    border-top: rem(4px) solid $dark;
    animation: rotating 1s infinite cubic-bezier(0.75, 0.25, 0.25, 0.75);
  }

  b,
  strong {
    font-weight: 700;
  }
}
.pg-content {
  background-color: rgba($white, 0.9);
  backdrop-filter: blur(0.5rem);
  position: relative;
  z-index: 3;
  // min-height: 576px;
  border-radius: rem(8px);
  &.banner-slider {
    height: 0;
    padding-bottom: 32.25%;
  }
  @media (min-width: $md) {
    &.banner-slider {
      padding-bottom: 21.5%;
    }
  }
  @media (min-width: $xl) {
    &.banner-slider {
      padding-bottom: 16.125%;
    }
  }
  @media (min-width: $xxl) {
    &.banner-slider {
      padding-bottom: 21.5%;
    }
  }
}

.dashboard-container {
  .dash-t-move {
    cursor: move;
  }
  & > div {
    display: flex;
    flex-wrap: wrap;
  }
}

.myprof-gradelevels {
  columns: 2;
  @media (min-width: $md) {
    columns: 4;
  }
}

.pg-sticky {
  &.js-is-sticky {
    z-index: 2896;
    // width: 100%;
  }
  .pg-sticky-content {
    background-color: rgba($gray-200, 0.9);
    backdrop-filter: blur(0.5rem);
    // height: rem(128px);
    transition: background-color 250ms ease;
  }
}
.pg-sticky-nav {
  z-index: 994;
  align-self: flex-start;
  &.js-is-sticky {
    // align-self: flex-end;
    align-self: flex-start;
  }
}

.path-shortcut-btn {
  background-color: $white;
  border-radius: rem(0 4px 4px 0);
  border: rem(1px) solid $light;
  color: $gray-700;
  cursor: pointer;
  line-height: 1.2;
  text-align: left;
  width: 100%;
}
.path-box,
.path-shortcut-btn {
  position: relative;
  &:before {
    left: rem(-2px);
    top: 0;
    height: 100%;
    width: rem(2px);
    content: "";
    position: absolute;
  }
  &.theme-0 {
    &:before {
      background-color: $brandAqua;
    }
  }
  &.theme-1 {
    &:before {
      background-color: $brandYellow;
    }
  }
  &.theme-2 {
    &:before {
      background-color: $brandBlue;
    }
  }
  &.theme-3 {
    &:before {
      background-color: $brandRed;
    }
  }
}
.path-shortcut-btn {
  &:before {
    top: rem(0px);
    height: calc(100% + #{rem(1px)});
  }
}
.oneitem-box {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 95%;
}
.oneitem-btn-holder {
  width: 100%;
}
.oneitem-mask-section {
  //pointer-events: none;
  //background-color: rgba($gray-100, 0.85);
  background-color: rgba(222, 224, 226, 0.5);
}
.oneitem-mask {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba($gray-100, 0.85);
  background-color: rgba(222, 224, 226, 0.5);
}

.oneitem-mask-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba($gray-100, 0.85);
  background-color: $brandBlue;
  border-radius: 0.5rem;
  opacity: 0.2;
}
// d-flex justify-content-between align-items-center py-2 px-1 p-md-3
.oneitem-btn-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slick-arrow {
  position: absolute;
  top: 0;
  height: 0;
  width: rem(30px);
  z-index: 2;
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  &.type-banner {
    padding-bottom: 32.25%;
    &.slick-prev {
      left: rem(0);
    }
    &.slick-next {
      right: rem(0);
    }
  }
  &.type-activity {
    // padding-bottom: 100%;
    height: 100%;
    &.slick-prev {
      left: rem(0);
    }
    &.slick-next {
      right: rem(0);
    }
  }
  &.type-course {
    padding-bottom: 32.25%;
    @media (min-width: $md) {
      padding-bottom: calc(32.25% / 2);
    }
    @media (min-width: $lg) {
      padding-bottom: calc(32.25% / 3);
    }
    @media (min-width: $xl) {
      padding-bottom: calc(32.25% / 4);
    }
  }
  &.slick-prev {
    left: rem(-16px);
  }
  &.slick-next {
    right: rem(-16px);
  }
}
.slick-slider.banner-slider {
  .slick-list {
    overflow: visible;
  }
}

.page-loader-comp,
.content-loader-comp {
  background-color: rgba($gray-700, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 4848;
  display: flex;
  opacity: 0;
  transition: opacity 500ms ease;
  &.active {
    opacity: 1;
  }
  .tkl-spinner {
    z-index: 5848;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    border: rem(4px) solid rgba($white, 0.25);
    border-top: rem(4px) solid $white;
    animation: rotating 1.2s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
}
.content-loader-comp {
  background-color: rgba($gray-100, 0.9);
  .tkl-spinner {
    border: rem(4px) solid rgba($gray-700, 0.25);
    border-top: rem(4px) solid $gray-700;
  }
}
.cursor-move {
  cursor: move !important;
}
.cart-item {
  border-top-width: rem(2px) !important;
}
.cart-total {
  border-top-width: rem(4px) !important;
}
.cart-item-avatar {
  width: rem(32px);
  @media (min-width: $md) {
    width: rem(48px);
  }
}
.cart-code-rec {
  position: absolute;
  top: 0;
  left: 0;
  color: $white;
  text-transform: uppercase;
  line-height: 1;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  small {
    font-weight: 700;
  }
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 24px 24px 0 0;
    left: 100%;
    top: 0;
  }
  &.bg-brandred {
    &:after {
      border-color: $brandRed transparent transparent transparent;
    }
  }
  &.bg-dark {
    &:after {
      border-color: $dark transparent transparent transparent;
    }
  }
}
.login-content {
  background-color: rgba(#faf5fb, 0.75);
  backdrop-filter: blur(#{rem(8px)});
  min-width: rem(320px);
  border-radius: rem(8px);
  @media (min-width: $md) {
    width: rem(480px);
  }
  @media (min-width: $xl) {
    // width: rem(48px);
  }
}
.maintenance-content {
  background-color: rgba(#faf5fb, 0.75);
  backdrop-filter: blur(#{rem(8px)});
  min-width: rem(320px);
  border-radius: rem(8px);
  @media (min-width: $md) {
    width: rem(720px);
  }
  @media (min-width: $xl) {
    // width: rem(48px);
  }
}
.login-wrap {
  min-height: 60vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  @media (min-width: $md) {
    min-height: 75vh;
  }
  @media (min-width: $xl) {
    // width: rem(48px);
  }
  .logo-wrap {
    svg {
      height: 100%;
      width: 100%;
    }
  }
  .btn-holder {
    .btn {
      min-width: rem(120px);
    }
  }
}
.border-dotted {
  border-style: dotted !important;
}

.cta-comp.btn-secondary {
  &:disabled {
    &:hover {
      background: $secondary !important;
    }
  }
}
.cta-comp.btn-primary {
  &:disabled {
    &:hover {
      background: $primary !important;
    }
  }
}

.icon-font {
  .material-icons {
    font-size: 20px;
  }
}

.btn-outline-default {
  &:disabled {
    background: transparent !important;
    border: none;
    color: #212121;
    cursor: not-allowed;

    &:hover {
      color: #fff;
      background: $primary !important;
    }
  }
}
.cta-comp {
  &:disabled {
    //background: transparent !important;
    border: none;
    color: #212121;
    cursor: not-allowed;

    &:hover {
      color: #fff;
      background: $primary !important;
    }
  }
}
// .cta-comp {
//   &:disabled {
//     background: transparent !important;
//     border: none;
//     color: #212121;
//     cursor: not-allowed;

//     &:hover {
//       color: #fff;
//       background: $primary !important;
//     }
//   }
// }
.btn-text-primary {
  color: $primary;
}

.btn-outline.btn-primary {
  color: #fff;
  background: $primary;
  border: $primary;

  &:hover {
    background-color: lighten($primary, 10%);
    border-color: lighten($primary, 10%);
  }
}

.btn-outline.btn-danger {
  color: #fff;
  background: $danger;
  border: $danger;

  &:hover {
    background-color: lighten($danger, 10%);
    border-color: lighten($danger, 10%);
  }
}

.moduleBackground {
  background-color: $gray-300;
}

.btn-outline.btn-secondary {
  color: #fff;
  background: $secondary;
  border-color: $secondary;
  border: $secondary;
  &:hover {
    color: #fff;
    background-color: lighten($secondary, 10%);
    border-color: lighten($secondary, 10%);
  }
}

.btn-outline.btn-success {
  color: #fff;
  background: $success;
  border-color: $success;
  &:hover {
    color: #fff;
    background-color: lighten($success, 10%);
    border-color: lighten($success, 10%);
  }
}

.btn-unfilled {
  color: $green !important;
  background-image: none !important;
  background-color: transparent !important;
  border: 1px solid $green !important;
  &:hover {
    color: #fff !important;
    background-color: $green !important;
    border-color: $green !important;
  }
}

.btn-cancel.btn-secondary {
  color: $secondary;
  background-color: transparent;
  border-color: $secondary;
  &:hover {
    color: #fff;
    background-color: $secondary;
    border-color: $secondary;
  }
}

.btn-outline {
  border-width: 2px;
  padding: 0.483rem 0.933rem;

  &.btn-xs {
    padding: 0.15rem 0.5rem;
  }

  &.btn-sm {
    padding: 0.25rem 0.65rem;
  }

  &.btn-lg {
    padding: 0.45rem 1rem;
  }

  &.btn-xl {
    padding: 0.75rem 2rem;
  }

  &.btn-outline-thin {
    border-width: 1px;
  }
}

.btn-rounded-icon {
  border-radius: 50px;
  padding: 0;
  width: 50px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &[data-hash] {
    > i {
      pointer-events: none;
    }
  }
}

/* Box Shadow */

.box-shadow-1:before {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.33;
  content: "";
  box-shadow: 90px 30px 90px #bbb;
  transition: all 0.2s ease-in-out;
}

.box-shadow-1.box-shadow-1-hover:before {
  opacity: 0;
}

.box-shadow-1.box-shadow-1-hover:hover:before {
  opacity: 0.33;
}

.box-shadow-2 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.box-shadow-2:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.box-shadow-3 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5), 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.box-shadow-4 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.box-shadow-5 {
  box-shadow: 0 15px 60px -36px rgba(0, 0, 0, 0.45);
}

.rounded-border {
  border-radius: 0.5rem !important;
  //border-radius: 0rem;
}

.rounded-pill {
  border-radius: 50rem !important;
  //border-radius: 0rem !important;
}

.rounded-pill-textbox {
  border-radius: 2rem !important;
}

.text-info {
  color: $primary !important;
}

.text-blue {
  color: #3F749C !important;
}

.btn-background-style-next {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
  text-decoration: none;
}

.modal-backdrop {
  width: 100%;
  height: 100%;
}

.curser-pointer:hover {
  cursor: pointer;
}
.cursor-pointer-hidden:hover {
  cursor: auto;
}

.font-24 {
  font-size: 24px !important;
}

.btn-purple:not(:disabled):not(.disabled):active,
.btn-purple:not(:disabled):not(.disabled).active,
.btn-purple:not(:disabled):not(.disabled):hover,
.show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #c64bc5;
  border-color: #c64bc5;
}

.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:not(:disabled):not(.disabled).active,
.btn-blue:not(:disabled):not(.disabled):hover,
.show > .btn-richText.dropdown-toggle {
  color: #fff;
  background-color: #0088cc;
  border-color: #0088cc;
}

.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:not(:disabled):not(.disabled).active,
.btn-blue:not(:disabled):not(.disabled):hover,
.show > .btn-richText.dropdown-toggle {
  color: #fff;
  background-color: #0088cc;
  border-color: #0088cc;
}

.btn-black:not(:disabled):not(.disabled):active,
.btn-black:not(:disabled):not(.disabled).active,
.btn-black:not(:disabled):not(.disabled):hover,
.show > .btn-richText.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-black:not(:disabled):not(.disabled):active,
.btn-black:not(:disabled):not(.disabled).active,
.btn-black:not(:disabled):not(.disabled):hover,
.show > .btn-richText.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline.btn-black {
  color: #fff;
  background: #000;
  border-color: #000;
  &:hover {
    color: #fff;
    background-color: lighten(#000, 10%);
    border-color: lighten(#000, 10%);
  }
}

.btn-outline.btn-blue {
  color: #fff;
  background: #0088cc;
  border-color: #0088cc;
  &:hover {
    color: #fff;
    background-color: darken(#0088cc, 10%);
    border-color: darken(#0088cc, 10%);
  }
}
.btn-outline.btn-purple {
  color: #fff;
  background-color: #c64bc5;
  border-color: #c64bc5;
  &:hover {
    color: #fff;
    background-color: #c64bc5;
    border-color: #c64bc5;
  }
}

.btn-purple {
  color: #fff;
  background-color: #c64bc5;
  border-color: #c64bc5;
  &:hover {
    color: #fff;
    background-color: #c64bc5;
    border-color: #c64bc5;
  }
}

.btn-green:not(:disabled):not(.disabled):active,
.btn-green:not(:disabled):not(.disabled).active,
.btn-green:not(:disabled):not(.disabled):hover,
.show > .btn-green.dropdown-toggle {
  color: #fff;
  background-color: #1a9f93;
  border-color: #1a9f93;

  &:hover {
    color: #fff;
    background-color: darken(#1a9f93, 10%);
    border-color: darken(#1a9f93, 10%);
  }
}

.btn-outline.btn-green {
  color: #fff;
  background: #1a9f93;
  border-color: #1a9f93;
  &:hover {
    color: #fff;
    background-color: darken(#1a9f93, 10%);
    border-color: darken(#1a9f93, 10%);
  }
}
.btn-dark-blue:not(:disabled):not(.disabled):active,
.btn-dark-blue:not(:disabled):not(.disabled).active,
.btn-dark-blue:not(:disabled):not(.disabled):hover,
.show > .btn-dark-blue.dropdown-toggle {
  color: #fff;
  background-color: #41739b;
  border-color: #41739b;
  &:hover {
    color: #fff;
    background-color: darken(#41739b, 10%);
    border-color: darken(#41739b, 10%);
  }
}

.btn-outline.btn-dark-blue {
  color: #fff;
  background: #41739b;
  border-color: #41739b;
  &:hover {
    color: #fff;
    background-color: darken(#41739b, 10%);
    border-color: darken(#41739b, 10%);
  }
}

.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled).active,
.btn-orange:not(:disabled):not(.disabled):hover,
.show > .btn-orange.dropdown-toggle {
  color: #fff;
  background-color: #fcb227;
  border-color: #fcb227;
}

.btn-outline.btn-orange {
  color: #fff;
  background: #fcb227;
  border-color: #fcb227;
  &:hover {
    color: #fff;
    background-color: darken(#fcb227, 10%);
    border-color: darken(#fcb227, 10%);
  }
}

.btn-outline.btn-blue-dark {
  color: #fff;
  background: #305978;
  border-color: #2d526f;
  &:hover {
    color: #fff;
    background-color: darken(#305978, 10%);
    border-color: darken(#2d526f, 10%);
  }
}

blockquote {
  background: #f9f9f9;
  border-left: 4px solid #ccc;
  margin: 1em 15px;
  padding: 0.8em 10px;
}
blockquote:before {
  color: #ccc;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

::-webkit-scrollbar {
  width: 16px; /* for vertical scrollbars */ //8px //instance change
  height: 8px; /* for horizontal scrollbars */
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: $gray-100;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
}

.modal-learnerPopup-DiscussionForm-box {
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: darken(#0088cc, 5%);
  }
}

input[type="radio"]:checked:after {
  background-color: darken(#1dbaad, 7.5%);
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #fff;
}

.custom-checkbox_addResource {
  input[type="checkbox"] {
    height: 1.5em;
    width: 1.5em;
    cursor: pointer;
    position: relative;
    -webkit-transition: 0.1s;
    // border-radius:4em;
  }
}

.rich-text-editor,
.tabs-accordian,
.learnerPopup-DiscussionForm-box,
.modal-popup-file-upload,
.modal-learnerPopup-DiscussionForm-box,
.fileUploadGroup {
  // display: flex;
  a {
    text-decoration: underline;
    color: blue;
    background-color: transparent;
    &:hover {
      text-decoration: underline;
      color: darken(blue, 7.5%);
      background-color: transparent;
    }
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: darken(#0088cc, 5%);
  }
  img {
    float: left;
    margin: 0.5rem;
  }

  figure {
    margin: 0 0 0rem;
  }

  .rdw-image-center {
    display: block;
    justify-content: flex-start;
  }
}

.tox-edit-area > * {
  ::-webkit-scrollbar {
    width: 8px; /* for vertical scrollbars */
    height: 8px; /* for horizontal scrollbars */
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $gray-100;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
  }
}

.mce-content-body > * {
  td {
    vertical-align: top !important;
  }
}

.DraftEditor-editorContainer {
  background-color: white;
  border-left: 0.1px solid transparent;
  position: relative;
  z-index: 1;
}

body {
  scrollbar-width: thin;
}

.capital-case {
  text-transform: uppercase;
}

.btn-tkl {
  background-color: #dd1d45;
  border-bottom: 1px solid #dd1d45;
  border-color: #dd1d45;
  color: #fff;

  &:hover {
    color: #fff;
    background-color: lighten(#dd1d45, 10%);
    border-color: lighten(#dd1d45, 10%);
  }
}

.btn-purple-light {
  background-color: #f171f0 !important;
  border-bottom: 1px solid #f171f0 !important;
  border-color: #f171f0 !important;
  color: #fff !important;

  &:hover {
    color: #fff;
    background-color: lighten(#f171f0, 10%) !important;
    border-color: lighten(#f171f0, 10%) !important;
  }
}

.btn-purple {
  color: #fff !important;
  background-color: #c64bc5 !important;
  border-color: #c64bc5 !important;
  &:hover {
    color: #fff;
    background-color: #c64bc5;
    border-color: #c64bc5;
  }
}

.btn-notification {
  background-color: transparent;
  border-bottom: 1px solid transparent;
  border-color: transparent;
  color: transparent;
  font-size: xx-large;
  position: relative;

  right: 1rem;
  height: 40px;
  &:hover {
    color: transparent;
    background-color: transparent;
    border-color: transparent;
  }
}

.btn-notification:not(:disabled):not(.disabled):active,
.btn-notification:not(:disabled):not(.disabled).active,
.show > .btn-notification.dropdown-toggle {
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0 0 0 0.2rem transparent;
}

.btn-notification:focus,
.btn-notification.focus {
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0 0 0 0.2rem transparent;
}

.btn-notification:not(:disabled):not(.disabled):active:focus,
.btn-notification:not(:disabled):not(.disabled).active:focus,
.show > .btn-notification.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem transparent;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.btn-black {
  background-color: #000;
  border-color: #000;
  color: #fff;
  font-weight: 600;
}

.btn-outline-tkl {
  border: 1px solid #dd1d45;
}
.required-field {
  color: #dd1d45;
}

.btn-tkl:not(:disabled):not(.disabled):active,
.btn-tkl:not(:disabled):not(.disabled).active,
.show > .btn-tkl.dropdown-toggle {
  color: #fff;
  background-color: #dd1d45;
  border-color: #dd1d45;
}

.show-validation-text-error {
  color: #dc3545;
  font-weight: 600;
  margin-bottom: 0px;
}

.show-button-border-error {
  border: 2.8px solid #dc3545;
}
.TKL_number_highlight {
  color: #3f749c;
}
small {
  .text-primary {
    margin-top: 6px;
  }
}

.show-txt-error {
  color: #dc3545;
  font-weight: 600;
}

.font-semi-bold-TKL {
  font-weight: 600 !important;
}

.card-box {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.card-box:hover {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
}

.card-box-notify {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
}

.card-box-notify:hover {
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.2);
}

.gray-font {
  color: #6c757d;
}

.font-weight-700 {
  font-weight: 700;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-300 {
  font-weight: 300;
}
.font-weight-200 {
  font-weight: 200;
}
.font-weight-100 {
  font-weight: 100;
}

.bg-alice-blue {
  background-color: aliceblue;
}

.rounded-box {
  border-radius: 1.5rem;
}

.custom-file-label {
  border-radius: 50rem !important;
}

.single-select-dropdown {
  ::-webkit-scrollbar {
    width: 10px; //8px;; /* for vertical scrollbars */
    height: 8px; /* for horizontal scrollbars */
  }
}

.multi-select-dropdown {
  ::-webkit-scrollbar {
    width: 10px; //8px;; /* for vertical scrollbars */
    height: 8px; /* for horizontal scrollbars */
  }
}

.rounded-comment {
  border-radius: 1.5rem !important;
}

.cta-btn-a-tag {
  i {
    margin-top: 0.1em;
  }
}

.center-align {
  text-align: center;
}
.center-header {
  margin-left: 1em;
}
.text-align-center {
  text-align: center;
}

.alert.alert-info {
  color: $primary;
  background-color: lighten($primary, 55%);
  border-color: lighten($primary, 50%);
}

.react-datetime-picker__calendar,
.react-datetime-picker__clock {
  z-index: 10 !important;
}

.btn-width {
  width: 7rem;
}

.btn-width-10 {
  width: 10rem;
}

.border-primary {
  border: 3px solid $primary;
}

.border-tkl {
  border: 3px solid #dc3545;
}

.bg-light-gray {
  background-color: rgba(238, 238, 238, 0.5) !important;
}

.outline-none {
  outline: none;
}

.program-selection {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  padding: 5em;
}

.justify-content-space-between {
  justify-content: space-between;
}

.btn-outline-noLike {
  color: #18998e;
  background-color: #fff;
  border-color: #18998e;
  &:hover {
    color: #18998e;
    background-color: #fff;
    border-color: #18998e;
  }
}
.btn-outline-Liked {
  color: #fff;
  background-color: #18998e;
  border-color: #18998e;
  &:hover {
    color: #fff;
    background-color: #18998e;
    border-color: #18998e;
  }
}

.btn-outline-nobookmark {
  color: #40403f;
  border-color: #40403f;
  &:hover {
    color: #40403f;
    border-color: #40403f;
  }
}
.btn-outline-bookmarked {
  color: #fff;
  background-color: #40403f;
  border-color: #40403f;
  &:hover {
    color: #fff;
    background-color: #40403f;
    border-color: #40403f;
  }
}
.pointer_events_none {
  pointer-events: none;
}

.disabled_termEnded {
  opacity: 0.9;
  pointer-events: none;
  filter: brightness(25%);
}

.disabled_termNotStarted {
  opacity: 0.9;
  pointer-events: none;
  filter: brightness(25%);
}

.blur_content {
  filter: blur(4px);
  pointer-events: none;
}
.btn-outline-transparent {
  color: #fff;
  background-color: transparent;
  &:hover {
    color: #fff;
    background-color: transparent;
  }
}
.tkl-spinner-logoLoader {
  z-index: 5848;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  svg {
    width: 80px;
    height: 100%;
    overflow: visible;
  }

  // position: absolute;
  // width: 5%;

  // animation: transformSvg 3s infinite cubic-bezier(0.75, 0.25, 0.25, 0.75);
  #green {
    animation: loaderBlue 1s infinite alternate;
  }
  #yellow {
    animation: loaderRed 1s infinite alternate;
  }
  #red {
    animation: loaderYellow 1s infinite alternate;
  }
  #blue {
    animation: loaderGreen 1s infinite alternate;
  }
}
@keyframes loaderBlue {
  from {
    transform: translate(-25%, -25%);
  }
  to {
    fill: #1dbaad;
    transform: translate(0%, 0%);
  }
}

@keyframes loaderRed {
  from {
    transform: translate(25%, -25%);
  }
  to {
    fill: #fcb127;
    transform: translate(0%, 0%);
  }
}
@keyframes loaderYellow {
  from {
    transform: translate(-25%, 25%);
  }
  to {
    fill: #df1c45;
    transform: translate(0%, 0%);
  }
}
@keyframes loaderGreen {
  from {
    transform: translate(25%, 25%);
  }
  to {
    fill: #3f749c;
    transform: translate(0%, 0%);
  }
}
.text_secondary_color {
  color: #40403f;
  font-weight: 700;
}

.title_underline_hover:hover {
  text-decoration: underline;
}
.tkl-community-spinner {
  position: absolute;
  left: 50%;
  z-index: 100;
  bottom: 0.2%;
}
.p-relative {
  position: relative;
}

.dropdown-menu {
  border-radius: 10px;
  .list-unstyled {
    margin: 0;
  }
}

.react-datetime-picker__wrapper {
  border: none !important;
}
.react-datetime-picker__clear-button {
  display: none !important;
}

.p-absolute {
  position: absolute;
}
.t-0 {
  top: 0px;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    /* CSS specific to iOS devices */
    .re_use_multiselect .no_dropdown {
      height: 36px;
      border-radius: 0px !important;
      background: linear-gradient(179deg, #dedede, #fff);
    }
    .re_use_multiselect .with_dropdown {
      height: 36px;
      border-radius: 0px !important;
      background: linear-gradient(179deg, #dedede, #fff);
    }

    .create_newAccount {
      color: #000 !important;
    }
  }
}
.pagination_items {
  overflow: auto;
}
.width-auto {
  width: auto;
}

.text-left {
  text-align: left;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  display: table-cell !important;
}

.text-gray {
  color: #40403f;
}

.isNotFiles {
  .modal-dialog {
    margin-top: 5%;
  }
}
.preview_files_container {
  .modal-dialog {
    max-width: 80vw;
    .modal-content {
      max-height: 90% !important;
    }
    .modal-body {
      max-height: 70vh;
      .embed_media {
        width: 100%;
        height: 95%;
      }
    }
  }
}

.prgrs-bar-comp.progress {
  height: rem(6px);
  background-color: $gray-300;
}
@each $color, $value in $theme-colors {
  .CircularProgressbar.progress-#{$color} {
    .CircularProgressbar-trail {
      stroke: $gray-300;
      stroke-width: rem(8px);
    }
    .CircularProgressbar-path {
      stroke: $value;
      stroke-width: rem(8px);
    }
    .CircularProgressbar-text {
      fill: $value;
      font-weight: 700;
      font-size: 1.5em;
    }
    // .ld-spinner {
    //   border-color: rgba(theme-color-level($color, $alert-color-level), 0.25);
    //   border-top-color: rgba(theme-color-level($color, $alert-color-level), 1);
    // }
  }
  // &.alert-#{$color} {
  //   .ld-spinner {
  //     border-color: rgba(theme-color-level($color, $alert-color-level), 0.25);
  //     border-top-color: rgba(theme-color-level($color, $alert-color-level), 1);
  //   }
  // }
}

body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  .display-1,
  .display-2,
  .display-3,
  .display-4,
  .lead {
    font-weight: 300;
  }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0; //!important;
    border-bottom-right-radius: 0; //!important;
  }

  .custom-tabs-link {
    a.nav-link {
      border: rem(1px) solid transparent !important;
      background-color: $white;
      color: $gray-500;
      position: relative;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: rem(4px) solid $gray-500 !important;
      &.active {
        background-color: transparent;
        border-bottom: rem(4px) solid $primary !important;
        color: $primary;
      }
    }
    .nav-item {
      position: relative;
      &:before {
        background-color: $gray-700;
        content: "";
        display: block;
        height: rem(2px);
        left: -8px;
        margin-top: rem(-1px);
        position: absolute;
        top: 50%;
        width: rem(8px);
        display: none;
      }
      &:first-child {
        &:before {
          display: none;
        }
      }
    }
  }
  .custom-tabs-pane {
    .tab-pane {
      display: none !important;
      &.active {
        display: flex !important;
      }
    }
  }
  .accordion-toggle {
    background-color: $white;
    border-bottom: rem(4px) solid $gray-500;
    border: 0;
    color: $gray-500;
    position: relative;
    text-align: left;
    width: 100%;
    &.active {
      // background-color: $gray-300;
      color: $primary;
      border-bottom: rem(4px) solid $primary;
    }
    .accordion-icn {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
  .review-table {
    th,
    td {
      line-height: 1.2;
      padding: rem(16px 8px);
    }
    th small {
      font-weight: 700;
    }
  }
  .td-last {
    border-bottom: rem(2px) solid $gray-500 !important;
  }
  .rounded-xl {
    border-radius: rem(8px) !important;
  }
  .rounded-xxl {
    border-radius: rem(16px) !important;
  }
  .review-marksheet {
    border: rem(2px) dashed $gray-700;
  }
  .border-top-left-0 {
    border-top-left-radius: 0 !important;
  }
  .border-top-right-0 {
    border-top-right-radius: 0 !important;
  }
  .border-bottom-left-0 {
    border-bottom-left-radius: 0 !important;
  }
  .border-bottom-right-0 {
    border-bottom-right-radius: 0 !important;
  }
  .modal-backdrop {
    z-index: 4040;
  }
  .modal {
    z-index: 4050;
  }
  .modal + .modal-backdrop {
    z-index: 4140;
  }
  .modal + .modal-backdrop + .modal {
    z-index: 4150;
  }
}

@import './../../imports';
@import './scss/default';

// DON'T ADD ANY SCSS IN XS
@media (min-width: $xs) {
  @import './scss/xs';
}

@media (min-width: $sm) {
  @import './scss/sm';
}
@media (min-width: $md) {
  @import './scss/md';
}
@media (min-width: $lg) {
  @import './scss/lg';
}
@media (min-width: $xl) {
  @import './scss/xl';
}
@media (min-width: $xxl) {
  @import './scss/xxl';
}

@import './../../imports';
@import './scss/default';

// DON'T ADD ANY SCSS IN XS
@media (min-width: $xs) {
  @import './scss/xs';
}

@media (min-width: $sm) {
  @import './scss/sm';
}
@media (min-width: $md) {
  @import './scss/md';
}
@media (min-width: $lg) {
  @import './scss/lg';
}
@media (min-width: $xl) {
  @import './scss/xl';
}
@media (min-width: $xxl) {
  @import './scss/xxl';
}

table {border: none;}

.mce-notification {display: none !important;}
.tox-notifications-container {display: none !important;}
.tox-tinymce-aux {
  z-index: 9999 !important;
}

.tox-pop tox-pop--left{
  display: none;
}

.tox .tox-pop.tox-pop--left::before{
  display: none;
}
.tox-pop__dialog{
  display: none;
}


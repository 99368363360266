* {
  box-sizing: border-box;
}
.scrollable-editor {
  max-height: 200px; 
  overflow-y: auto;
}
.settings-header-style {
  display: flex;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 0em !important;
  background-color: #f5c645;
  .settings-req {
    display: inline-flex;
    align-items: center;
    color: black;
    font-weight: 600;
  }
  .settings-toggle {
    display: inline-flex;
    margin-left: auto;
    .btn-collapse-style {
      background-color: transparent;
      outline: none;
      // border-color: black;
      border: 2.5px solid black;
      font-size: 0.355em;
      border-radius: 100%;
      width: 80%;
      height: 80%;
    }
    .btn-opacity-style {
    }
  }
  .btn-outline-secondary {
    border-color: #6c757d;
    :hover {
      color: #fff;
      background-color: none !important;
    }
    :focus {
      background-color: none !important;
    }
  }
}
.row-spacing {
  padding-bottom: 1.2em;
}
.save-settings {
  display: flex;
  justify-content: flex-end;
}

.btn-opacity-style {
  .toggle-remove-style {
    color: black;
    font-size: 3em;
    font-weight: bolder;
    margin-left: -0.35em;
    margin-top: -0.25em;
  }
}
.container-styles {
  label {
    margin-top: 0.5em;
  }

  .label-align-withRows {
    margin-left: 0.3em;
  }
  .select-styles {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; /* Remove default arrow */
    width: 100%;
  }
  .label-margin-top {
    margin-top: 0.5em;
  }
}
.activity-type-style {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-size: 18.5px;
}
.color-grey {
  color: grey;
}
.edit-icon-style {
  display: inline;
  font-weight: 600 !important;
}
.create-icon-style {
  color: darken(#1dbaad, 7.5%);
}
@media (max-width: 2560px) {
  .create-icon-style {
    font-size: 1em !important;
    margin-top: -0.25em;
    margin-left: 0.5em;
  }
}
@media (max-width: 1440px) {
  .create-icon-style {
    font-size: 1.4em !important;
    margin-top: -0.25em;
    margin-left: 0.5em;
  }
}
@media (max-width: 768px) {
  .create-icon-style {
    font-size: 1.3em !important;
    margin-top: -0.25em;
    margin-left: 0.5em;
  }
}
.block-elemnt {
  display: block;
}
.show-border-error {
  border: 2.8px solid #dc3545;
}
.wrap-style {
  word-break: break-word;
}
/*Modal popup styles only here */

.modal-popup {
  .modal-dialog {
    max-width: 85%;
    margin: 5% auto;
  }
  .modal-title {
    font-weight: 500;
  }
  .modal-header {
    padding-bottom: 0.5rem;
  }
  .modal-body {
    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
      height: 100%;
      .left-content {
        width: 40%;
      }
      .right-content {
        width: 55%;
        overflow-y: auto;
        // height: 300px;//change
        height: 100%;
        padding-left: 10px;
      }
      ::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px grey;
        border-radius: 10px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: darken(#0088cc, 5%);
        border-radius: 10px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #0088cc;
      }
    }
  }
  .button-align-in-input-popup {
    position: sticky; //fixed
    margin-top: 0.2em;
    svg {
      fill: darken(#1dbaad, 7.5%); //E15639
    }
  }
  .input-style {
    height: 28px;
    overflow: hidden;
  }
  .input-limit-in-input {
    textarea {
      padding-right: 50px;
      overflow: hidden;
    }
    // .glyphicon{
    //  // position: absolute;
    // }
  }
  .footer-padding {
    padding: 5px 20px;
  }
  .sortable-ghost {
    background-color: #2499ed;
  }

  .sortable-drag {
    background-color: #2499ed;
  }

  .form-check-label:checked:after {
    width: 1rem;
    height: 1rem;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: darken(#1dbaad, 7.5%);
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid #fff;
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="-4-488"%3e%3ccircle r="3" fill="%23fff"/%3e%3c/svg%3e');
  }
  .delete-svg {
    fill: #e15639 !important;
  }
  .div-input-modal {
    padding-bottom: 0.5em;
    padding-right: 0.5em;
  }
  .input-caret {
    caret-color: auto;
    white-space: normal;
  }
  label {
    word-break: break-word;
  }
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden;
}
/*upto here*/

/* radio group styles only*/
.radioGroup {
  .correct-answer {
    margin-top: 0.5em;
    color: darken(#1dbaad, 7.5%);
    margin-bottom: 0.5em;
  }
  .incorrect-answer {
    margin-top: 0.5em;
    color: #dc3545;
    margin-bottom: 0.5em;
  }
  .submit-styles {
    margin-top: 0.8em;
  }
  .correct_Answer_show_style:checked ~ label {
    color: #116d65;
  }
  .incorrect_Answer_show_style:checked ~ label {
    color: #dc3545;
  }
  .correct_Answer_show_style_not_checked ~ label {
    color: #116d65 !important;
  }
  .default-container-styles {
    margin-left: 1em !important;
    margin-right: 1em !important;
    &.container-styles {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
      border: 1px solid #5a6268;
      padding: 0.2em;
      border-radius: 15px;
      .left-content {
        width: 45%;
        border-right: 1px solid #5a6268;
        padding: 0.2em;
      }
      .right-content {
        width: 52%;
      }
    }
  }
}
.margin-style-add {
  margin-top: 0.8rem !important;
  margin-left: 0.8rem !important;
}
.element-add-style {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  cursor: pointer;
}
.modal-popup-question-box {
  textarea {
    border-radius: 15px;
  }
  .modal-body {
    //  height: 25em;
    .container {
      .left-content {
        //  height: 380px;//change
        overflow-y: auto;
        .scrollbar-padding {
          padding-bottom: 0.5em;
          padding-right: 0.5em;
        }
      }
      .right-content {
        // height: 350px;//change
      }
    }
  }
}
.questionboxgroup {
  textarea {
    //border-radius: 15px;
  }
  .container-styles {
    .right-content-flex-style {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    .right-content-flex-style > div {
      flex: 1 50%;
    }
    .item-width {
      width: 100%;
    }
  }
}
.whole-content {
  display: flex;
  justify-content: space-evenly;
  flex: 1;
  flex-wrap: wrap;
}
.container-elements {
  // height: 380px;//prev
  overflow-y: auto;
  textarea {
    border-radius: 15px;
  }
}

.modal-popup-input-button {
  // ::-webkit-scrollbar {
  //   width: 8px;
  // }
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: darken(#0088cc, 7.5%);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #0088cc;
  }
}
.buttonMenu {
  background-color: #e9e9e9;
}

.fragments {
  padding: 0.25rem !important;
}
.input-btn-styles {
  margin-bottom: 0.8em;
  margin-top: 0.8em;
  color: rgb(29, 66, 58);
  width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// #course_description {
//   height: 300px !important;
// }
/*  upto here   */
/*modal-popup-DiscussionForm-box*/
.modal-popup-DiscussionForm-box .container .center-content .tox-tinymce {
  height: 350px; //ch
  width: 100% !important;
}
.modal-popup-DiscussionForm-box {
  .container {
    .center-content {
      width: 100%;
      // height: 380px;//prev
      overflow-y: auto;
      padding-right: 1em;
      textarea {
        border-radius: 15px;
      }

      ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
    }
  }
}

.modal-popup-DiscussionForm-box .modal-dialog {
  max-width: 100%;
  margin: 5% auto;
  margin-top: 10px;
}

/*upto here */

/* discussion -form-styles*/
.discussionFormgroup {
  .center-content {
    // border: 2px solid gray;
    width: 100%;
    padding: 1em;
    box-shadow: 1px 1px 3px 0px;
  }
  .icon-style {
    font-size: 1.2em;
    padding-right: 0.5em;
    cursor: pointer;
    // &:active {
    // }
  }
  .circle-avatar {
    border-radius: 50%;
    border: 1px solid gray;
    padding: 0.15em;
    cursor: pointer;
  }
  .icon-style:after {
    content: "";
    padding-top: 1px;
    margin-left: 20px;
    border-right: 1px solid gray;
    margin-left: 0.5em;
  }
  .discussion-input-styles {
    width: 100%;
    padding: 1.2em 0em;
    border: 1px solid gray;
    border-radius: 20px;
    .circle-avatar {
      margin-right: 0.5em;
      padding: 0.2em;
      margin-left: 0.5em;
    }
    .input-text-holder {
      display: inline-flex;
      width: 92%;
    }
  }
}
.modal-learnerPopup-DiscussionForm-box {
  .modal-body {
    //height: 12em;
    overflow: auto;
    height: 400px;
  }
  //change container 2
  .container {
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    // flex-wrap: nowrap;
    .center-content {
      width: 100%;
      .subscribe-styles {
        display: block;
        color: #2499ed;
        cursor: pointer;
        .material-icons {
          font-size: larger;
          padding-right: 0.2em;
        }
      }
      .disc-section {
        width: 100%;
        padding: 1.2em 0em;
        border: 1px solid gray;
        border-radius: 20px;
        margin-top: 1.5em;
        ::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 10px grey;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: darken(#0088cc, 7.5%);
          border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #0088cc;
        }

        .circle-avatar {
          padding: 0.5em;
          margin-left: 0.5em;
          border: 1px solid gray;
          border-radius: 50%;
          margin-top: -0.2em;
          max-height: 3rem;
        }
        .input-text-holder {
          display: inline-flex;
          width: 90%;
          margin-left: 0.5em;
        }
      }
      .disc-section-editor-on {
        display: inline-flex;
        //height: 300px;//change
        .circle-avatar-editor-on {
          // margin-left: 0.2em;
          display: inline-table;
        }
        .tox-tinymce {
          margin-left: 0.5em;
          margin-right: 0.5em;
          width: 100%;
          height: 250px; //ch
        }
      }
    }
  }
}

.dt-picker {
  .react-datetime-picker {
    .react-datetime-picker__calendar {
      .react-calendar {
        width: 300px;
        max-width: 100%;
      }
    }
    .react-datetime-picker__wrapper {
      border: none;
      .react-datetime-picker__inputGroup {
        input {
          &:focus {
            outline: none !important;
          }
        }
        .react-datetime-picker__inputGroup__leadingZero {
          margin-top: 0.8em;
          // font-weight: bold;
        }
      }
      .react-datetime-picker__clear-button {
        display: none;
      }
      .react-datetime-picker__calendar-button {
        &:focus {
          outline: none !important;
        } //outline change
        .react-datetime-picker__calendar-button__icon {
          width: 1.2em;
          margin-top: -0.2em;
          stroke: #5a6268;
          :hover {
            stroke: darken(#1dbaad, 7.5%);
          }
          :focus {
            border: none;
          }
        }
      }
    }
  }
}

.selectBox-popup {
  .container {
    .left-content {
      textarea {
        border-radius: 20px !important;
      }
      //  height: 350px;//prev
      overflow-y: auto;
    }
    .right-content {
      // height: 350px !important;//prev
      overflow-y: auto;
      .border-radius-limit {
        border-radius: 20px !important;
      }
      .selectBox-Options {
        padding: 0.4em;
      }
      .remove-choice-buttons {
        padding-bottom: 0.5em;
      }
    }
  }
}

.selectBoxGroup {
  .default-container-styles {
    .right-content {
      .selectbox-choices {
        display: flex;
        justify-content: space-between;
        padding: 0.8em;
        border-radius: 10px;
        border: 1px solid #dedede;
        margin-bottom: 1em;
        .choiceText {
          width: 90%;
          word-break: break-word;
        }
        .Dropdown-root {
          width: 20%;
          height: 42px;
          .Dropdown-control {
            padding-right: 12px;
            white-space: nowrap;
            .Dropdown-placeholder {
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

.fragment-card-style {
  //border: 2px solid #dedede !important;
}

.show-border-error-color {
  color: #dc3545;
}

.comments-section-styles {
  background-color: #dedede; // rgba(245, 241, 225, 0.6); //
  height: auto;
  overflow: auto;
  width: 100%;
  min-height: 500px;
  max-height: 1000px;
  .comments-popup {
    margin-top: 0.8em;
    margin-left: 1.5em;
    //  border-top: 2px solid black;
    // width: inherit;
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    .hover-style-edit {
      &:hover {
        background-color: #f5e495;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
    .hover-style-delete {
      &:hover {
        background-color: #f5e495;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    .edit-delete-styles {
      width: max-content;
      float: right;
      margin-top: 1.5em;
      margin-right: -20px;
    }
  }
  .comment {
    // padding-top: 1em;
    overflow: auto;
    //width: max-content;
    width: 100%;

    //forum-ui-change

    padding-top: 0.2em;
    padding-bottom: 0.5em;

    .circle-avatar {
      padding: 0.2em;
      // margin-left: 0.5em;
      border: 1px solid black;
      border-radius: 50%;
      margin-top: -0.2em;
      margin-right: 0.5em;
      display: inline-block; //can be removed
      font-size: 15px;
      font-weight: 500;
    }
    .profile-name-styles {
      margin-top: -2em;
      display: inline-block;
      // max-width: 75%;
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      top: 0.5em;
      font-size: 15.5px;
      color: dimgray;
      font-weight: 700;
    }
    .comment-updated-styles {
      margin-left: 1em;
      position: relative;
      top: 2.8px;
      color: dimgray;
      font-weight: 500;
      font-size: 14px;
    }
    .edit-delete-icons {
      float: right;
      cursor: pointer;
      margin-right: "10px";
      position: relative;
      top: 0.5em;
    }
    .comment-styles {
      padding: 0.5rem;
      //   margin: 1.4em;
      //forum ui change
      margin: 0.6em 1em 0.8em 1em;
      overflow-wrap: break-word;
      color: black;
      font-size: 16px;
      background-color: white;
      border-radius: 10px;
    }
    .comment-footer-styles {
      // margin-left: 3.4em;
      //forum ui change
      margin-left: 1.5em;
      margin-top: -0.8em;
      .like-text {
        color: #338fbd;
        font-weight: 600;
        cursor: pointer;
      }
      .like-comment-seperator {
        padding-left: 0.25em;
        font-size: 1.2em;
        font-weight: 600;
      }
      .icon-styles {
        padding: 0.2em;
        font-size: 22px;
        cursor: pointer;
      }
      .reply-styles {
        padding: 0.6em;
        font-size: 15px;
        display: inline-block;
        cursor: pointer;
        color: #338fbd;
        font-weight: 600;
      }
      .tox-tinymce {
        height: 220px; //ch
        // width: 100%;//change
        width: 90%;
      }
      .other-comments-show {
        color: darken(#1dbaad, 7.5%);
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .other-comments-hide {
        color: darken(#1dbaad, 7.5%);
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.sort-by-dropdown-styles {
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
  .Dropdown-control {
    width: 10em;
    border-radius: 50em;
    padding: 8px 12px 8px 10px;
    .Dropdown-arrow-wrapper {
      .Dropdown-arrow {
        top: 20px;
      }
    }
  }
  .Dropdown-menu {
    width: 10em;
    border-radius: 10px;
  }
}
@media (max-width: 750px) {
  .modal-learnerPopup-DiscussionForm-box
    .container
    .center-content
    .disc-section {
    .input-text-holder {
      width: 80%;
    }
  }
}
@media (max-width: 350px) {
  .modal-learnerPopup-DiscussionForm-box
    .container
    .center-content
    .disc-section {
    .input-text-holder {
      width: 70%;
    }
  }
}
@media (min-width: 102em) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 130rem;
  }
}
.first-to-comment {
  display: flex;
  justify-content: center;
  font-style: italic;
  //align-items: center;
  // background-color: #dede;
  //margin-top: 1em;
  position: relative;
  //top: -6em;
}
.first-to-comment-bgimage {
  background-image: url("../../Screenshot\ \(160\).png");
  //background-position: inherit;
  background-repeat: no-repeat;
  filter: blur(2px);
  height: 100%;
  background-size: 100%;
  background-size: contain;
}
.first-to-comment-styles {
  //height: inherit;
  height: 100%;
  margin-top: 0.5em;
}

@media (max-width: 2560px) {
  .comments-section-styles .comment .profile-name-styles {
    max-width: 80%;
  }
}
@media (max-width: 1440px) {
  .comments-section-styles .comment .profile-name-styles {
    max-width: 75%;
  }
}

@media (max-width: 1024px) {
  .comments-section-styles .comment .profile-name-styles {
    max-width: 65%;
  }
}
@media (max-width: 768px) {
  .comments-section-styles .comment .profile-name-styles {
    max-width: 55%;
  }
}
@media (max-width: 425px) {
  .comments-section-styles .comment .profile-name-styles {
    max-width: 40%;
  }
}

@media (max-width: 375px) {
  .comments-section-styles .comment .profile-name-styles {
    max-width: 28%;
  }
}

@media (max-width: 320px) {
  .comments-section-styles .comment .profile-name-styles {
    max-width: 18%;
  }
}

@media (max-width: 425px) {
  .modal-learnerPopup-DiscussionForm-box
    .container
    .center-content
    .disc-section-editor-on {
    // height: 100%;
    .tox-tinymce {
      // height: 100% !important;
    }
  }
  .comments-section-styles .comment .comment-footer-styles .tox-tinymce {
    height: 400px !important;
  }
}
@media (max-width: 320px) {
  .comments-section-styles .comment .comment-footer-styles .tox-tinymce {
    height: 480px !important;
  }
}

@media (max-width: 1022px) {
  .discussionFormgroup
    .center-content
    .discussion-input-styles
    .input-text-holder {
    width: 82%;
  }
}
@media (max-width: 450px) {
  .discussionFormgroup
    .center-content
    .discussion-input-styles
    .input-text-holder {
    width: 60%;
  }
}
@media (max-width: 550px) {
  .btn-mobile-devices-style {
    margin-top: 1em;
  }
}
.modal-popup-file-upload {
  .modal-body {
    .container-elements {
      // height: 280px;
      .Dropdown-control {
        border-radius: 30px;
        padding: 3px 12px;
      }
      .Dropdown-menu {
        border-radius: 20px;
      }
    }
  }
}
.fileUploadGroup {
  .file-uploaded-data {
    display: flex;
    flex-wrap: wrap;
    .file-containers {
      border: 2px solid #dedede;
      border-radius: 20px;
      padding: 0.5em;
      width: 100%;
      //flex: 1 0 40%;
      flex-basis: 45%;
      margin-right: 0.12em;
      margin-bottom: 0.12em;
      position: relative;
      margin-bottom: 0.5em;
      .delete-div {
        position: relative;

        .file-delete-icon {
          font-size: 22px;
          cursor: pointer;
        }
      }
      .file-names {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        word-break: break-word;
        color: #317589;
        cursor: pointer;
        width: 95%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        // width: 90%;
      }
    }
  }
}

@media (min-width: 500px) {
  .fileUploadGroup {
    .file-uploaded-data {
      .file-containers {
        margin-right: 1em;
      }
    }
  }
}
@media (max-width: 950px) {
  .fileUploadGroup {
    .file-uploaded-data {
      .file-containers {
        .file-names {
          width: 90%;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .fileUploadGroup {
    .file-uploaded-data {
      .file-containers {
        .file-names {
          width: 85%;
        }
      }
    }
  }
}
.react-datetime-picker__clock {
  display: none;
}
// .react-datetime-picker__inputGroup__input {
//   //height: calc(100% - 1px) !important;
// }
.react-datetime-picker__inputGroup__input:invalid {
  color: #dc3545 !important;
  background-color: unset !important;
}
.file-upload-styles {
  @media (min-width: 960px) {
    display: inline-flex !important;
    //width: 30%;
    width: 100%;
    // margin-left: 1em;
    .select-styles-dropdown {
      width: 100%;
      .Dropdown-menu {
        //transform: translateY(-120%);
      }
    }
    ::-webkit-scrollbar {
      width: 8px;
      height: 2px;
    }
  }
}
.input-file-upload-style {
  display: inline-flex;
  width: 70%;
  margin-right: 10px;
  height: 2.5em;
}
.btn-upload-style {
  background-color: darken(#1dbaad, 7.5%);
  border-radius: 50em;
  color: white;
  width: 15%;
}
@media (max-width: 850px) {
  .btn-upload-style {
    width: 20%;
  }
}
@media (max-width: 520px) {
  .btn-upload-style {
    width: 35%;
  }
  // @media (max-width: 480px) {
  //   .btn-upload-style {
  //     width: 28%;
  //   }
  .input-file-upload-style {
    width: 55%;
  }
}
@media (max-width: 350px) {
  .btn-upload-style {
    width: 30%;
  }
  .input-file-upload-style {
    width: 40%;
  }
}
.fragment-spacing-styles {
  margin-bottom: 0.8em;
}

.points-style {
  margin-right: 1em;
  margin-top: 0.2em;
  margin-bottom: 0;
  font-size: 15px;
  font-family: sans-serif;
}

.modal-popup-radio {
  textarea {
    border-radius: 15px;
  }
}
.modal-popup-checkbox {
  textarea {
    border-radius: 15px;
  }
}
.question-box-height {
  height: 100%;
}
// .form-label {
//   white-space: pre-wrap;
// }

.every-question-mandatory {
  margin-top: 0.8em;
  margin-left: 1.5em;
  color: rgb(159, 26, 32);
  font-weight: 600;
  font-size: 18px;
}
.disable-dropdown-selectbox-fragment {
  background-color: #dedede;
}
.timer-styles {
  position: sticky;
  top: 0px;
  padding-top: 2.3em;
  z-index: 100;
}
.timer-styles-content-developer {
  padding-top: 2.3em;
}
.timer-styles-p-tag {
  &.timer_blink {
    animation: blinker 0.85s linear 60;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.modal-popup-imageslider-box {
  .custom-control-label-checkbox {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
  }
  .custom-control-label-checkbox::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adadad solid 1px;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 0.25rem;
  }
  .custom-control-label-checkbox::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%;
  }
  .showSliderTitle {
    margin-top: 0.25em;
  }
  .sliderTitle {
    margin-left: 0.5em;
    font-weight: 500;
  }
}
.imageSliderStyles {
  .carousel-container {
    .react-multi-carousel-track {
      li {
        display: flex;
      }
    }
    .react-multiple-carousel__arrow--left {
      outline: none;
      left: 0;
      min-width: 30px;
      min-height: 30px;
    }
    .react-multiple-carousel__arrow--right {
      outline: none;
      right: 0;
      min-width: 30px;
      min-height: 30px;
    }
  }
  .react-multiple-carousel__arrow::before {
    font-size: 14px;
  }
}
// @media only screen and (min-device-width: 1450px) and (max-device-width: 3000px) {
//   .carousel-container-styles-flex {
//     .img-txt-container {
//       // min-height: 600px;
//       overflow: hidden;
//     }
//   }
// }
.carousel-container-styles-flex {
  .img-txt-container {
    border-right: 2px solid #171716;
    border-top-right-radius: 10px;
    .img-div {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // overflow: hidden;
      .img-styles {
        vertical-align: initial !important;
        // flex-shrink: 0;
        // min-width: 100%;
        // min-height: 100%;
      }
    }
    .Slider-content {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      padding: 1em;
      // margin-top: 1em;
      // position: absolute;
      width: 100%;
      bottom: 0;
      background-color: rgba(232, 231, 227, 1);

      overflow: auto;
      overflow-wrap: break-word;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px grey;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #0088cc;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #0088cc;
    }
  }
}
#autoplayId {
  .input-formcontrol_style {
    border-top-right-radius: 50em !important;
    border-bottom-right-radius: 50em !important;
  }
}

// .modal-dialog {
//   min-width: 90%;
//   margin-top: 2% !important;
// }
// .modal-content {
//   /* 80% of window height */

//   // height: 90%  !important;
//   // max-height: 90% !important; //prev
//   min-height: 100% !important;
// }

.modal-body {
  /* 100% = dialog height, 120px = header + footer */
  max-height: calc(100% - 120px);
  // overflow-y: auto;
}

.modal-dialog,
.modal-content {
  //height: 90% !important;
  max-height: 90% !important;
}

.scroll-popup {
  //activites adding
  .modal-dialog,
  .modal-content {
    height: 90% !important;
    //max-height: 90% !important;
  }
}

.btn-secondary {
  background-color: transparent;
  background-image: none;
  border: 0px;
  color: #212121;
}

.btn-red {
  background-color: #9f1a20;
  border-color: #9f1a20;
  color: #fff;
}

.btn-red-filled {
  background-color: #9f1a20;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-red-filled:hover {
  background-color: #c3272b; /* Brighter red for hover effect */
}

// .btn-primary {
//   background-color: transparent;
//   background-image: none;
//   border: 0px;
//   color: #212121;
// }

.btn-dark {
  background-color: transparent;
  background-image: none;
  border: 0px;
  color: #212121;
}

.finish_activity_styles {
  justify-content: center !important;
}
#save-settings-text {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 0.8em;
  margin-right: 1.2em;
  font-size: 18px;
  color: rgb(19, 120, 111);
}
.c-avtr {
  height: 20px;
}

.nextactivity-flex-box {
  display: flex;
  justify-content: space-between;
  .right-container {
    height: fit-content;
    margin-top: 0.5em;
    .nextactivity-container {
      margin-right: 4em;
      // margin-top: 1em;
      display: flex;
      .nextactivity-div-styles {
        border: 2px solid #dedede;
        height: 14px;
        width: 35px;
        border-radius: 2px;
        margin-right: 1.5px;
      }
      .nextactivity-container-color-visited {
        background: linear-gradient(#96d5fa, #0a7fc4);
      }
      .nextactivity-container-color-notvisited {
        background: linear-gradient(#dedede, #b6bcbf);
      }
    }
  }
}

// .form-check {
//   display: inline-block;
// }
// .override-disabled-background {

// }
.cta-comp:disabled {
  //color: #fff;
}
.total-points-earned {
  p {
    margin: 0;
  }
  display: inline-flex;
  justify-content: flex-end;

  span {
    color: #18998e;
    margin-left: 0.25em;
  }
}

.correct-incorrect-styles {
  display: inline-flex;
  width: 50%;
}

.points-earned-text-questionBox,
.points-earned-text-fileUpload {
  display: flex;
  .correct-answer {
    margin-left: 1.5em;
    width: 30%;
    padding-right: 0.5em;
  }
  .total-points-earned {
    .possible-points {
      margin-top: 0.5em;
      margin-right: 2em;
      color: #18998e;
    }
    .points-earned {
      input {
        width: 40px;
      }
      margin-right: 1.2em;
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
    }
  }
}

.flex-style {
  display: flex;
}

.pre-wrap-class,
.left-content {
  white-space: pre-wrap;
}

.div-input-modal {
  .input-modal-relative-container {
    position: relative;
    .custom-file-label {
      width: calc(100% - 10%);
    }
    .glyphicon {
      display: inline;
      cursor: pointer;
      position: absolute;
      margin-left: 92%;
      margin-top: -3.2em;
      svg {
        margin-top: 2.4em;
        height: 1.5em;
      }
    }
  }
}
.glyphicon-remove {
  display: inline;
  cursor: pointer;
  position: absolute;
  margin-top: -3.2em;
  margin-left: calc(100% - 8%);
  svg {
    margin-top: 2.4em;
    height: 1.5em;
  }
}

.container-elements {
  .Tabs-element {
    .tab-type {
      display: flex;
      #tab-type-id {
        width: 10em;
      }
    }
    .tab-data {
      margin-top: 1.2em;
      padding-bottom: 0.8em;
      // border-bottom: 2px solid #adadad;
      .remove-choice-buttons {
        margin-top: 0.8em;
      }
    }
  }
}

.tab-accordioan-styles {
  .tab-data {
    display: flex;
    .header {
      margin: 0;
      cursor: pointer;
      width: 100%;
      border-right: 2px solid rgba(0, 0, 0, 0.2);
      max-height: 5em;
      // display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      overflow: auto;
      padding-top: 0.2em;
      padding-left: 0.5em;
    }
    .header:last-child {
      border-right: unset;
    }
  }
  .tab-content {
    display: flex;
    margin-top: 0.8em;
    padding: 0.5em;
  }
  .tab-data-content {
    border: 2px solid #dedede;
  }
  .accordian-data {
    border: 2px solid #dedede;
    padding: 0.8em;
    border-radius: 15px;
    .header {
      white-space: pre-wrap;
      font-weight: 600;
    }
    .accordian-content {
      font-weight: 400;
      line-height: 1.3em;
    }
  }
}
.selectBox-Options {
  .selectBox_answer_not_required {
    display: flex;
    justify-content: flex-start;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
    margin-left: -0.5em;
    strong {
      margin-left: 0.5em;
    }
  }
}
.responsive-caret {
  svg {
    margin-top: 2.4em;
  }
}
.btn-save-width {
  width: 85px;
}

.btn-cancel-width {
  width: 85px;
}

@media (max-width: 410px) {
  .responsive-caret {
    svg {
      margin-top: 3.8em;
    }
  }
}
.selectBox-Options {
  .option-block {
    display: flex;
  }
}
.radioGroup-block {
  margin-top: 1.2em;
  .radioGroup-flex {
    display: flex;
  }
}
.discussion-cancel-align {
  margin-top: 0.2em !important;
}
.hide-fragments-for-schedule-release {
  filter: blur(4px);
  pointer-events: none;
}
.modal-popup {
  input[type="radio"] {
    margin-top: 0.4em;
  }
}
@-moz-document url-prefix() {
  .btn-opacity-style {
    .toggle-remove-style {
      font-size: 3.5em;
      margin-top: -0.26em;
    }
  }
}

/* custom dropdown arrow- styles - start*/
.container-styles {
  .select-container {
    position: relative;
    .select-arrow-icon {
      position: absolute;
      right: 0.4em;
      font-size: 1.4em;
      color: black;
      font-weight: bold;
      top: 0.15em;
      outline: none;
      pointer-events: none;
    }
    select {
      padding-left: 0.5em;
      cursor: pointer;
    }
  }
}
@-moz-document url-prefix() {
  .container-styles {
    .select-container {
      .select-arrow-icon {
        top: 0.2em;
      }
      select {
        border: 1px solid black;
      }
    }
  }
  .react-datetime-picker {
    .react-datetime-picker__wrapper {
      .react-datetime-picker__inputGroup {
      }
    }
    .react-datetime-picker__inputGroup__amPm {
      -webkit-appearance: none;
      padding: 0px 15px 2px 0px;
      border: none;
      background-image: url("https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png");
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 10px;
    }
  }
}
.select-icon-container {
  position: relative;
  .select-arrow-icon {
    position: absolute;
    right: 0em;
    font-size: 1.5em;
    top: 1.28em;
    font-weight: bold;
    outline: none;
    pointer-events: none;
    padding-right: 0.2em;
  }
  .icon-hide {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; /* Remove default arrow */
    cursor: pointer;
  }
}
.numberBar-scrolling {
  input[type="number"]::-webkit-inner-spin-button {
    opacity: 0;
  }
  &:hover {
    input[type="number"]::-webkit-inner-spin-button {
      opacity: 1;
    }
  }
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
    &:hover {
      -moz-appearance: unset;
      appearance: unset;
      margin: 0;
    }
  }
}

.activity-title {
  font-weight: bold !important;
  color: #3e847b;
}
.finish_activity_styles {
  .btn-complete {
    background-color: #9f1a20;
    border-color: #9f1a20;
    color: #fff;
  }
}
.activity-class-flex {
  display: flex;
  justify-content: space-between;
  .activity-class-icon {
    margin: 0;
    color: rgb(51, 143, 189);
    cursor: pointer;
    .material-icon-message {
      margin-right: 0.2em;
      font-size: 1.8em;
      margin-top: -0.2em;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 410px) {
  .comments-section-styles .comment .comment-updated-styles {
    margin-left: 0.3em;
  }
}
.comment-footer-styles {
  .other-comments {
    .material-icon-response-style {
      font-size: 1.5em;
      margin-top: -0.2em;
      padding-right: 0.28em;
      color: #338fbd;
    }
  }
}
.Button-position-element {
  display: flex;
  margin-top: 1.5em;
  margin-bottom: 1em;
  .btn-label {
    width: calc(100px - -100px);
  }
}

.course-objective {
  display: flex;
}

.empty_div div:nth-child(1) {
  height: 100vh;
  .sortable-ghost {
    height: 20vh;
    svg {
      display: none;
    }
  }
}

.main-panel {
  .sortable-ghost {
    svg {
      display: none;
    }
  }
}
@media (max-width: 550px) {
  .nextactivity-flex-box {
    display: block;
  }
}
.discussion-faq-modal {
  overflow: auto !important;
  .modal-dialog {
    margin-top: 8% !important;
    max-width: 800px;
  }
}
.modal-styles-for-tinymceText {
  overflow: auto;
  .modal-dialog .modal-content {
    //  height: auto !important;
    .tox-tinymce {
      @media (max-width: 600px) {
        height: 650px !important;
      }
    }
  }
}

.accordion-styles-for-objectives {
  margin-top: 1em;
  .card {
    .card-header {
      padding: 0;
      background-color: #fff;

      .accordion-toggle {
        color: #494949;
        .accordion-icn {
          font-size: 25px;
        }
      }
      .accordion-toggle.active {
        color: #18998e !important;
        border-bottom: 0.25rem solid #18998e;
      }
    }
  }
}

.modal-learnerPopup-DiscussionForm-box .modal-dialog,
.modal-learnerPopup-DiscussionForm-box .modal-content {
  height: 100vh !important;
  max-height: 100% !important;
}
.modal-learnerPopup-DiscussionForm-box .modal-dialog {
  max-width: 100%;
  margin: 5% auto;
  margin-top: 10px;
}

.modal-popup-DiscussionForm-box .modal-dialog,
.modal-popup-DiscussionForm-box .modal-content {
  height: 100vh !important;
  max-height: 100% !important;
}

.upload_file_pointsAssignedStyles {
  @media (min-width: 960px) {
    display: flex;
    justify-content: space-between;
  }
}

.points-available {
  font-size: 18.5px;
  font-weight: 600;
}
.points-earned {
  font-size: 18.5px;
  font-weight: 600;
}

.instructor_feedback_learner {
  border: 2px solid;
}
.sm_devices_wrap {
  @media (max-width: 600px) {
    width: 100vh;
  }
}

// @media only screen and (min-device-width: 1450px) and (max-device-width: 3000px) {
//   .carousel-container-styles-flex {
//     .img-txt-container {
//       min-height: 600px;
//       overflow: hidden;
//     }
//   }
// }

.disableActivityEditPage {
  pointer-events: none;
}

.sitefooter-comp {
  nav {
    ul, li {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}



.cta-block__title {
  margin-bottom: 2rem;
}

.color-white {
  color: #fff;
}



.tkl-footer{
  color: white;
  font-weight: 500;
  .rounded-pill{
    border-radius:0px !important;
  }
  a {
    padding: 5px;
    color: white;
    text-decoration: none;
    background-color: transparent;
  }

  .cta-block {
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

h5 {
  font-size: 26px;
  line-height: 40px;
  font-weight: 700;
}
  h2 {
    font-size: 65px;
    line-height: 80px;
    font-family: Butler,Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif;
    font-weight: 400;
}
  .container{
    padding: 50px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 70px;
    padding-right: 70px;

  }
  .center{
    text-align: center;

  }

  ul {
    list-style-type: none;
    li{
      margin-left: -2.5em;
      padding-top: 1em;
    }
}

.tkl_social_footer{
  margin-left: -3rem;
  ul li{
    padding: .3rem;
    display: inline;
    margin: 0px;
  }
}

.padding-lr{
  padding-right: 4em;
  padding-left: 4em;
}


  #footer-div {
    display: flex;                  /* establish flex container */
    flex-direction: row;            /* default value; can be omitted */
    flex-wrap: nowrap;              /* default value; can be omitted */
    justify-content: space-between; /* switched from default (flex-start, see below) */
    background-color: lightyellow;
  }
  #container > div {
    width: 100px;
    height: 100px;
    border: 2px dashed red;
  }
}

.bg-black {
  background-color: #000 !important;
}

@media (max-width: 425px) {
  .footer__navigation{
    display: none;
  }
  .footer__resources{
    display: none;
  }
  
}
